import { CardIcon } from '@schibsted-regional/components';
import { clsx } from 'clsx';
import React, { useEffect, useState } from 'react';

import type { BenefitsPublications } from '../../../../types/frapi-types.js';
import type { BenefitsMenuItem } from './types.js';

import { LinkWithPulse } from '../../../../../core/mandatory-integrations/pulse/components/LinkWithPulse.js';
import { useOutsideClick } from '../../../../../core/react/hooks/use-handle-outside-click.js';
import { getCardUrl } from '../../../../resources/internal-routes.js';
import { MobileMenuNavigationLink } from './MobileMenuNavigationLink.js';

type Props = {
  publication: BenefitsPublications;
  devMode: boolean;
  title: string;
  open: boolean;
  links: BenefitsMenuItem[];
  onClose: () => void;
};

const initMenuHeight = 460;
const swipeToCloseTreshold = 140;

export const MobileMenuBottomSheet = ({
  publication,
  devMode,
  title,
  open,
  links,
  onClose,
}: Props) => {
  const [menuHeight, setMenuHeight] = useState(0);
  const [touchPosition, setTouchPosition] = useState<number | null>(null);
  const [ongoingSwipe, setOngoingSwipe] = useState(false);

  useEffect(() => {
    if (document) {
      document.body.style.overflow = open ? 'hidden' : 'auto';
    }
    setMenuHeight(open ? initMenuHeight : 0);
  }, [open, setMenuHeight]);

  const handleTouch = (e: React.TouchEvent) => {
    setOngoingSwipe(true);
    setTouchPosition(e.touches[0].clientY);
  };

  const handleSwipe = (e: React.TouchEvent) => {
    const currentTouch = e.touches[0].clientY;
    const touchDirection = touchPosition && touchPosition - currentTouch;

    if (touchDirection && menuHeight + touchDirection < initMenuHeight) {
      setMenuHeight(menuHeight + touchDirection);
    }
    setTouchPosition(currentTouch);
  };

  const handleSwipeEnd = () => {
    setOngoingSwipe(false);
    if (initMenuHeight - menuHeight > swipeToCloseTreshold) {
      onClose();
    } else {
      setMenuHeight(initMenuHeight);
    }
  };

  const closeSheet = () => {
    setOngoingSwipe(false);
    onClose();
  };

  return (
    <div
      className={clsx(
        'mobile-menu-bottom-sheet',
        open ? 'active' : 'inactive',
        ongoingSwipe ? '' : 'dropdown-transition',
      )}
      aria-hidden={open ? 'false' : 'true'}
      style={{ height: `${menuHeight}px` }}
      ref={useOutsideClick(closeSheet)}
    >
      <button
        className="close-menu"
        aria-label="lukk filter"
        onTouchStart={handleTouch}
        onTouchMove={handleSwipe}
        onTouchEnd={handleSwipeEnd}
        onClick={closeSheet}
      >
        <div className="close-menu-line"></div>
      </button>

      <h1 className="title">{title}</h1>

      <nav>
        {links.map((link) => (
          <MobileMenuNavigationLink key={link.to} {...link} />
        ))}
      </nav>

      <LinkWithPulse
        className="benefit-card-button"
        href={getCardUrl(publication, devMode)}
        id={'show-benefit-card'}
        name={'Shows the users Benefits card'}
      >
        <CardIcon />
        <span>Mitt fordelskort</span>
        <span className="benefits-card-button">Vis kort</span>
      </LinkWithPulse>
    </div>
  );
};
