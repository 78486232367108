import type { BenefitsPublications } from '../../types/frapi-types.js';
import type { FrontProps } from './front-components/Front.js';
import type { ArticlePageProps } from './article-components/article-page/ArticlePage.js';
import type { ArticlePagePromoProps } from './article-components/article-page/ArticlePagePromo.js';
import type { cardProps } from './card-components/Card.js';

import { frontendConfigWithOverwrite } from '../../../core/system/config/frontend.server.js';
import { withPulse } from '../../../core/mandatory-integrations/pulse/PulseProvider.js';

const withPulsePublication =
  (publication: BenefitsPublications) =>
  <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  (props: P) =>
    withPulse(
      Component,
      frontendConfigWithOverwrite(publication).pulseConfig,
    )(props);

const isArticlePageProps = (
  props: ArticlePageProps | FrontProps | ArticlePagePromoProps | cardProps,
): props is ArticlePageProps => {
  if ((props as ArticlePageProps).publication) {
    return true;
  }

  return false;
};
const isArticlePagePromoProps = (
  props: ArticlePageProps | FrontProps | ArticlePagePromoProps | cardProps,
): props is ArticlePagePromoProps => {
  if ((props as ArticlePagePromoProps).publication) {
    return true;
  }

  return false;
};
const isCardProps = (
  props: ArticlePageProps | FrontProps | ArticlePagePromoProps | cardProps,
): props is cardProps => {
  if ((props as cardProps).publication) {
    return true;
  }

  return false;
};

export const withPulseBenefits =
  <T extends ArticlePageProps | FrontProps | ArticlePagePromoProps | cardProps>(
    Component: React.FC<T>,
  ): React.FC<T> =>
  (props) => {
    if (
      isArticlePageProps(props) ||
      isArticlePagePromoProps(props) ||
      isCardProps(props)
    ) {
      return withPulsePublication(props.publication)(Component)(props);
    } else {
      return withPulsePublication(props.data.publication)(Component)(props);
    }
  };
