import styled from '@emotion/styled';

const LogoApCircularStyled = styled.div`
  background-image: url(https://cdn.aftenposten.no/ap/images/footer/circular-ap-logo.png);
  background-repeat: no-repeat;
  width: 64px;
  height: 64px;
  background-size: contain;

  img {
    max-width: 100%;
  }
`;

export { LogoApCircularStyled };
