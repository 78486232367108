import {
  CartIcon,
  EmailActionLetterIcon,
  HeartLineIcon,
  PlaneTripIcon,
  ShuffleIcon,
  TheaterIcon,
  WineGlassIcon,
  MusicNoteIcon,
  RestaurantIcon,
  DiplomaIcon,
  GridIcon,
  HandshakeIcon,
  PiggyBankIcon,
  ShipIcon,
  SoccerBallIcon,
} from '@schibsted-regional/components';

import type { Sections } from '../types/front-types.js';
import type { BenefitsPublications } from '../types/frapi-types.js';

export const btSections: Sections = [
  {
    text: 'Konserter',
    frontpage: true,
    icon: MusicNoteIcon,
    subsections: [
      {
        text: 'Bergen Live',
        id: 'f3ebeabb-9e6b-46d7-99c0-6a3b0fa6cabe',
        pathname: 'bergen-live',
      },
      {
        text: 'Grieghallen',
        id: 'fe47da47-137e-42a5-8f1c-a840ca80d2e5',
        pathname: 'grieghallen',
      },
      {
        text: 'Harmonien',
        id: '2bcd3c8e-5f71-43e5-97d1-9e62027d7f2e',
        pathname: 'harmonien',
      },
      {
        text: 'Festspillene',
        id: '3788b856-3ab6-401b-a93b-5b3c45173d85',
        pathname: 'festspillene',
      },
      {
        text: 'Andre',
        id: '75fdf900-0442-4662-a7a9-d16a393e1e12',
        pathname: 'andre',
      },
      {
        text: 'Julekonserter',
        id: 'f7a412f3-45c5-44ab-a0c2-b6eab9d3fca4',
        pathname: 'julekonserter',
      },
    ],
    id: '471f15a2-b0d3-4f55-9455-896dc1da0ca5',
    seo: 'BT Fordel er Bergens Tidende sin eksklusive fordelsprogram som gir deg utallige rabatter på en rekke gode konsertopplevelser på store og små scener. Du kan også delta i spennende konkurranser hvor du kan vinne billetter til populære konserter.',
    pathname: 'konserter',
  },
  {
    text: 'Mat og drikke',
    frontpage: true,
    icon: RestaurantIcon,
    id: '5a3e2d23-73fb-48e9-88cc-648974da39dd',
    seo: 'BT Fordel er Bergens Tidende sitt fordelsprogram. Enten du er en matelsker, en vinentusiast eller bare ønsker å utforske nye smaker, vil BT Fordel sørge for at du får glede av spennende mat- og drikkeopplevelser til reduserte priser.',
    pathname: 'mat-og-drikke',
  },
  {
    text: 'Revy og teater',
    frontpage: true,
    icon: TheaterIcon,
    subsections: [
      {
        text: 'Revy og stand up',
        id: '6e857af4-82bb-4a2c-b778-2cf838d7253a',
        pathname: 'revy-og-stand-up',
      },
      {
        text: 'Teater',
        id: '202730ab-40f1-486f-89e0-2f4054fa4d30',
        pathname: 'teater',
      },
      {
        text: 'Annet',
        id: '634b1377-0787-4f56-a26f-6cc74de97abe',
        pathname: 'annet',
      },
    ],
    id: 'af71ac4c-f7fa-4944-ab0d-6f4ccc50f39d',
    seo: 'BT Fordel, Bergens Tidende sitt fordelsprogram, kan du nyte fordelen av gode rabatter på flere av byens teater og revyforestillinger. Enten du er en teaterentusiast eller elsker å le av morsomme revyforestillinger. ',
    pathname: 'revy-og-teater',
  },
  {
    text: 'Familie',
    frontpage: true,
    icon: HeartLineIcon,
    id: '38f45b04-c6e1-4033-b5de-d14c33005321',
    seo: 'BT Fordel, Bergens Tidende sitt fordelsprogram, gir deg muligheten til å ta med deg familien eller venner og spare penger på en rekke aktiviteter som er egnet for hele familien eller gode venner.',
    pathname: 'familie',
  },
  {
    text: 'Sport og fritid',
    frontpage: true,
    icon: SoccerBallIcon,
    id: '78289823-1a4a-4fb0-9582-25a9905fe304',
    seo: 'BT Fordel, Bergens Tidende sitt fordelsprogram, gir deg spesielle fordeler innen sport og fritid. Dra nytte av 2 for 1 tilbud på kamper, få rabatt som deltaker på flere løp eller ulike kurs gjennom året.',
    pathname: 'sport-og-fritid',
  },
  {
    text: 'Reise',
    frontpage: true,
    icon: ShipIcon,
    subsections: [
      {
        text: 'Norge',
        id: 'daac449e-d701-4c18-972e-e6f10808ae2e',
        pathname: 'norge',
      },
      {
        text: 'Europa',
        id: 'b2f8b7eb-a8f8-4852-b60f-3218c490d39b',
        pathname: 'europa',
      },
      {
        text: 'Resten av verden',
        id: 'b4c84689-325e-4fdd-affe-76372396a329',
        pathname: 'resten-av-verden',
      },
    ],
    id: 'd6fe5f97-814d-475e-bd9e-213074a7ccfe',
    seo: 'BT Fordel er Bergens Tidende sin eksklusive fordelsprogram som gir deg utallige fordeler og rabatter på en rekke gode opplevelser. Gled deg over hundrevis av rabatter på konserter, revyer, teaterforestillinger, sportsarrangementer og andre spennende opplevelser for deg, din familie eller gode venner.',
    pathname: 'reise',
  },
  {
    text: 'Annet',
    frontpage: false,
    icon: PiggyBankIcon,
    id: '7e83eab0-1445-4689-9ec1-c64d0fbba113',
    seo: 'BT Fordel er Bergens Tidende sin eksklusive fordelsprogram som gir deg utallige fordeler og rabatter på en rekke gode opplevelser. Gled deg over hundrevis av rabatter på konserter, revyer, teaterforestillinger, sportsarrangementer og andre spennende opplevelser for deg, din familie eller gode venner.',
    pathname: 'annet',
  },
  // Keep this one last
  {
    text: 'BT Fordel',
    frontpage: false,
    icon: GridIcon,
    id: '',
    seo: 'BT Fordel er Bergens Tidende sin eksklusive fordelsprogram som gir deg utallige fordeler og rabatter på en rekke gode opplevelser. Gled deg over hundrevis av rabatter på konserter, revyer, teaterforestillinger, sportsarrangementer og andre spennende opplevelser for deg, din familie eller gode venner. ',
    pathname: 'bt-fordel',
  },
];

export const saSections: Sections = [
  {
    text: 'Familie',
    frontpage: true,
    icon: HeartLineIcon,
    subsections: [
      {
        text: 'Kongeparken',
        id: '2adfc158-f8a2-4803-a497-00fe239fe221',
        pathname: 'kongeparken',
      },
      {
        text: 'Museer',
        id: 'a55a15a3-943e-4b45-abba-79f1109bff92',
        pathname: 'museer',
      },
    ],
    id: '4fea7413-b275-46e8-9626-a23cfe639d43',
    seo: 'Fordel: Gjør noe gøy med familien! Som abonnent på Stavanger Aftenblad får du tilgang til Fordel, et fordelsprogram som gir deg rabatter på fornøyelsesparker, kino, familieforestillinger, museer og mer. Opplev, lær og spar penger med Fordel.',
    pathname: 'familie',
  },
  {
    text: 'Kultur',
    frontpage: true,
    icon: TheaterIcon,
    subsections: [
      {
        text: 'Debatt og foredrag',
        id: 'feaba6d6-5e5a-4d32-bd92-6f2ca56255fe',
        pathname: 'debatt-og-foredrag',
      },
      {
        text: 'Kino',
        id: '644f9fad-c6ea-4fec-8ae8-8ff1e78c3cc9',
        pathname: 'kino',
      },
      {
        text: 'Konserter',
        id: 'e8862900-e701-4d62-87b1-23dd209696d6',
        pathname: 'konserter',
      },
      {
        text: 'Underholdning',
        id: 'd05c64a3-7d22-448b-ac51-fa02c1cdc827',
        pathname: 'underholdning',
      },
    ],
    id: 'dbaf9fce-ae7d-4f38-9721-34e796f3722e',
    seo: 'Fordel er Stavanger Aftenblad sitt eksklusive fordelsprogram som gir deg utallige rabatter på en rekke gode kulturopplevelser på store og små scener. Du kan også delta i spennende konkurranser hvor du kan vinne billetter til populære arrangement.',
    pathname: 'kultur',
  },
  {
    text: 'Kurs',
    frontpage: true,
    icon: DiplomaIcon,
    id: '812970ae-bd21-454a-acc1-555ee1d80b3e',
    seo: 'Som abonnent på Stavanger Aftenblad får du tilgang til Fordel, et eksklusivt fordelsprogram som gir deg utallige fordeler og rabatter på en rekke gode opplevelser. Med Fordel kan du glede deg over hundrevis av rabatter på konserter, revyer, teaterforestillinger, sportsarrangementer og andre spennende opplevelser for deg og din familie. Du kan også spare penger på reiser, hoteller, restauranter og kino. Fordel er din billett til et rikere og mer variert liv.',
    pathname: 'kurs',
  },
  {
    text: 'Mat og drikke',
    frontpage: true,
    icon: RestaurantIcon,
    id: '77629e7f-6095-493e-80f2-5415d51b0b5e',
    seo: 'Fordel er Stavanger Aftenblad sitt fordelsprogram. Er du en vinentusiast, eller ønsker du å bli det? Sjekk ut de ulike vinkursene gjennom fordelsprogrammet! Du finner helt sikkert noe som kan hjelpe deg på vei til å bli en bedre vinkjenner. Eller hva med å gjøre hverdagen litt enklere? Bestill bord eller bring maten med deg hjem. Maten får du til rabattert pris med Fordel.',
    pathname: 'mat-og-drikke',
  },
  {
    text: 'Reiser',
    frontpage: true,
    icon: ShipIcon,
    subsections: [
      {
        text: 'Boreal Adventure',
        id: 'd0b75678-fffb-4d99-8849-9711e066a40e',
        pathname: 'boreal-adventure',
      },
      {
        text: 'Ekspedisjonsreiser',
        id: 'd9667940-dd0b-41ba-bdc3-69f3e14802a2',
        pathname: 'ekspedisjonsreiser',
      },
      {
        text: 'Elite Travel',
        id: '91b8d6b9-fe03-45f8-8985-60a5df90cb3a',
        pathname: 'elite-travel',
      },
      {
        text: 'Fjord Line',
        id: '37dc3dd4-cf08-47e5-adc4-715e8cc59671',
        pathname: 'fjord-line',
      },
      {
        text: 'Hurtigruten',
        id: 'f420bf79-b2c0-4e46-a337-3dd124001fd0',
        pathname: 'hurtigruten',
      },
      {
        text: 'IWannaGo',
        id: '7c3473a9-c61f-47f1-b8c4-183b1239cbe0',
        pathname: 'iwannago',
      },
      {
        text: 'Norsk Tur',
        id: '8f84fb87-7f09-4ce6-842d-f1f9d91ae6c0',
        pathname: 'norsk-tur',
      },
      {
        text: 'The Vibe',
        id: 'afecd732-ed58-4332-8b98-d341274853de',
        pathname: 'the-vibe',
      },
      {
        text: 'Togreiser.no',
        id: 'fbb5c8c4-524c-4a0a-814f-001bbfc836a1',
        pathname: 'togreiser.no',
      },
      {
        text: 'Vitus Reiser',
        id: 'd1579ebe-fecd-4baf-bd6e-54ec409030b5',
        pathname: 'vitus-reiser',
      },
    ],
    id: '75a19685-3f8f-4c11-a663-6da67eff0ab1',
    seo: 'Som abonnent på Stavanger Aftenblad får du tilgang til Fordel, et eksklusivt fordelsprogram som gir deg utallige fordeler og rabatter på en rekke gode opplevelser. Med Fordel kan du glede deg over hundrevis av rabatter på konserter, revyer, teaterforestillinger, sportsarrangementer og andre spennende opplevelser for deg og din familie. Du kan også spare penger på reiser, hoteller, restauranter og kino. Fordel er din billett til et rikere og mer variert liv.',
    pathname: 'reiser',
  },
  {
    text: 'Tjenester',
    frontpage: false,
    icon: HandshakeIcon,
    id: '2f50e708-faef-4d93-8026-164f9883bd6b',
    seo: 'Som abonnent på Stavanger Aftenblad får du tilgang til Fordel, et eksklusivt fordelsprogram som gir deg utallige fordeler og rabatter på en rekke gode opplevelser. Med Fordel kan du glede deg over hundrevis av rabatter på konserter, revyer, teaterforestillinger, sportsarrangementer og andre spennende opplevelser for deg og din familie. Du kan også spare penger på reiser, hoteller, restauranter og kino. Fordel er din billett til et rikere og mer variert liv.',
    pathname: 'tjenester',
  },
  // Keep this one last
  {
    text: 'Populært',
    frontpage: false,
    icon: GridIcon,
    id: '',
    seo: 'Som abonnent på Stavanger Aftenblad får du tilgang til Fordel, et eksklusivt fordelsprogram som gir deg utallige fordeler og rabatter på en rekke gode opplevelser. Med Fordel kan du glede deg over hundrevis av rabatter på konserter, revyer, teaterforestillinger, sportsarrangementer og andre spennende opplevelser for deg og din familie. Du kan også spare penger på reiser, hoteller, restauranter og kino. Fordel er din billett til et rikere og mer variert liv.',
    pathname: 'fordel',
  },
];

export const apSections: Sections = [
  {
    text: 'Mat og drikke',
    icon: WineGlassIcon,
    frontpage: true,
    subsections: [
      {
        text: 'Vinkurs',
        id: '968172b3-1659-4029-92b0-b72cf6f27456',
        pathname: 'vinkurs',
      },
      {
        text: 'Brennevin- og ølkurs',
        id: '1d0ad331-d6b4-47b9-89bc-eebaa8dbd829',
        pathname: 'brennevin--og-ølkurs',
      },
      {
        text: 'Smakstrender',
        id: 'b9ed5237-3a28-4e97-81e6-af26458a9f51',
        pathname: 'smakstrender',
      },
      {
        text: 'Vandringer',
        id: '871feab1-1a2a-4718-9cbd-c8c08883bb93',
        pathname: 'vandringer',
      },
      {
        text: 'Restauranter',
        id: '9a2fe102-0bcc-410b-b157-239c46f40409',
        pathname: 'restauranter',
      },
      {
        text: 'Matreiser',
        id: 'e35dcdcd-5096-4786-ab38-1e4d198367fa',
        pathname: 'matreiser',
      },
      {
        text: 'Matkurs',
        id: 'e7b2f40d-dc2b-4ef5-9941-be86f45f2663',
        pathname: 'matkurs',
      },
    ],
    id: '89aa3871-f4bf-499a-ae39-4b97d7e74c67',
    seo: 'Som abonnent på Aftenposten får du tilgang til A-kortet, et eksklusivt fordelsprogram som gir deg utallige fordeler og rabatter på en rekke gode opplevelser. Med A-kortet kan du glede deg over hundrevis av rabatter på konserter, revyer, teaterforestillinger, sportsarrangementer og andre spennende opplevelser for deg og din familie. Du kan også spare penger på reiser, hoteller, restauranter og kino. A-kortet er din billett til et rikere og mer variert liv.',
    pathname: 'mat-og-drikke',
  },
  {
    text: 'Reise',
    frontpage: true,
    icon: PlaneTripIcon,
    subsections: [
      {
        text: 'Europa',
        id: '67d87ce6-2dae-42e2-91f9-f8ed624714d0',
        pathname: 'europa',
      },
      {
        text: 'Norge/Skandinavia',
        id: 'd4061120-a992-49ff-8603-79317d4db7d1',
        pathname: 'norge/skandinavia',
      },
      {
        text: 'Afrika',
        id: '5b7e67e6-b93d-4c8d-a7dd-1789d5e76f64',
        pathname: 'afrika',
      },
      {
        text: 'Nord-Amerika',
        id: '52339352-05d1-4bf0-a0cb-365c6a0a338f',
        pathname: 'nord--amerika',
      },
      {
        text: 'Oseania',
        id: 'b6714171-249e-4cf1-8f4e-76f59e64bce3',
        pathname: 'oseania',
      },
      {
        text: 'Sør-Amerika',
        id: 'd1cb9f73-e25f-40fc-9925-41ea88902ff6',
        pathname: 'sør--amerika',
      },
      {
        text: 'Asia',
        id: '7a3d3ea4-0b44-4a8f-a268-6827916cf21f',
        pathname: 'asia',
      },
      {
        text: 'Elvecruise',
        id: '3b42df25-df0d-433a-a1e8-ffc2f3deb7c9',
        pathname: 'elvecruise',
      },
      {
        text: 'Temareiser',
        id: '85c2319e-16ec-4a95-9f79-7dbfbeaaf9b1',
        pathname: 'temareiser',
      },
      {
        text: 'Aktiv ferie',
        id: 'd9d531d7-3442-47e8-9457-86478bf90219',
        pathname: 'aktiv-ferie',
      },
      {
        text: 'Langtidsferie',
        id: '25986637-3b03-4c1d-bb23-6395c058efeb',
        pathname: 'langtidsferie',
      },
      {
        text: 'Overnattinger',
        id: 'ad4e4e40-eded-4cc5-8dc7-be8052f74668',
        pathname: 'overnattinger',
      },
      {
        text: 'Cruise',
        id: 'cee5f0e0-5204-4f89-9346-cf3a814d2d94',
        pathname: 'cruise',
      },
      {
        text: 'Midtøsten',
        id: 'bf56fd73-4b8a-42a2-88e5-81ff7ce27ca2',
        pathname: 'midtøsten',
      },
    ],
    id: 'bf13917b-e358-44ee-8bbc-1bae171b825e',
    seo: 'Som abonnent på Aftenposten får du tilgang til A-kortet, et eksklusivt fordelsprogram som gir deg utallige fordeler og rabatter på en rekke gode opplevelser. Med A-kortet kan du glede deg over hundrevis av rabatter på konserter, revyer, teaterforestillinger, sportsarrangementer og andre spennende opplevelser for deg og din familie. Du kan også spare penger på reiser, hoteller, restauranter og kino. A-kortet er din billett til et rikere og mer variert liv.',
    pathname: 'reise',
  },
  {
    text: 'Kultur',
    frontpage: true,
    icon: TheaterIcon,
    subsections: [
      {
        text: 'Forsalg konserter',
        id: '7218d566-016d-4165-8a46-e141da0cc634',
        pathname: 'forsalg-konserter',
      },
      {
        text: 'Festivaler',
        id: 'edee88a9-da0f-4dcc-bca4-802b2b9fe4ea',
        pathname: 'festivaler',
      },
      {
        text: 'Revy',
        id: 'eaaff4d0-d61c-4cf1-8d4b-0052f052a9d8',
        pathname: 'revy',
      },
      {
        text: 'Teater',
        id: '40acaa4c-34c1-4256-bb27-f645dbd1b6db',
        pathname: 'teater',
      },
      {
        text: 'Opera',
        id: '9fef3f1c-7bef-4b07-8432-4060387186f0',
        pathname: 'opera',
      },
      {
        text: 'Jazz',
        id: 'de82621c-4f3c-4add-a93f-331c05f14306',
        pathname: 'jazz',
      },
      {
        text: 'Klassisk',
        id: '9b16b7fc-bc59-4e33-a611-ad39f4bfdbd9',
        pathname: 'klassisk',
      },
      {
        text: 'Kino',
        id: '25260345-67f7-4656-97ee-00730bd43d3a',
        pathname: 'kino',
      },
    ],
    id: 'a8c95f0f-1b87-46a7-aac5-dc742186fd5b',
    seo: 'Som abonnent på Aftenposten får du tilgang til A-kortet, et eksklusivt fordelsprogram som gir deg utallige fordeler og rabatter på en rekke gode opplevelser. Med A-kortet kan du glede deg over hundrevis av rabatter på konserter, revyer, teaterforestillinger, sportsarrangementer og andre spennende opplevelser for deg og din familie. Du kan også spare penger på reiser, hoteller, restauranter og kino. A-kortet er din billett til et rikere og mer variert liv.',
    pathname: 'kultur',
  },
  {
    text: 'Familie og fritid',
    frontpage: true,
    icon: HeartLineIcon,
    subsections: [
      {
        text: 'Kurs',
        id: '02df1188-ea08-4869-b568-c23e1830e067',
        pathname: 'kurs',
      },
      {
        text: 'Holmenkollen skifestival',
        id: '762d18d5-316a-44d4-a3fa-d7d3bb10cb80',
        pathname: 'holmenkollen-skifestival',
      },
      {
        text: 'Barneteater',
        id: '6846eca0-ace9-4800-a421-6303ebe5c446',
        pathname: 'barneteater',
      },
      {
        text: 'Språkkurs',
        id: 'd67b6b92-52eb-4a48-8a0b-7da0cec45c2e',
        pathname: 'språkkurs',
      },
      {
        text: 'Mosjonsløp',
        id: '9cac60dd-329c-4417-9f29-aba927d26ef4',
        pathname: 'mosjonsløp',
      },
      {
        text: 'Shopping',
        id: 'f0a224dd-8bf1-4733-bb7c-bb882b64b40b',
        pathname: 'shopping',
      },
      {
        text: 'Fotokurs',
        id: '17fc6969-566c-4952-bf0d-3e700fea99cb',
        pathname: 'fotokurs',
      },
      {
        text: 'Sportsarrangement',
        id: '982c3236-1c14-4d2d-a9f3-706679173dc8',
        pathname: 'sportsarrangement',
      },
    ],
    id: '7ae4abec-0d34-415e-af9b-cd87bf3f3b9e',
    seo: 'Som abonnent på Aftenposten får du tilgang til A-kortet, et eksklusivt fordelsprogram som gir deg utallige fordeler og rabatter på en rekke gode opplevelser. Med A-kortet kan du glede deg over hundrevis av rabatter på konserter, revyer, teaterforestillinger, sportsarrangementer og andre spennende opplevelser for deg og din familie. Du kan også spare penger på reiser, hoteller, restauranter og kino. A-kortet er din billett til et rikere og mer variert liv.',
    pathname: 'familie-og-fritid',
  },
  {
    text: 'Aftenposten inviterer',
    frontpage: true,
    icon: EmailActionLetterIcon,
    subsections: [
      {
        text: 'Masterclasses',
        id: '953180c9-469b-43b9-8cae-6d09fced59f9',
        pathname: 'masterclasses',
      },
      {
        text: 'Redaksjonelle arrangementer',
        id: 'f001f9c4-3701-4b1b-8885-3d70656cb158',
        pathname: 'redaksjonelle-arrangementer',
      },
    ],
    id: '2c76da57-3d5c-4153-a4ba-e16cabc52da6',
    seo: 'Som abonnent på Aftenposten får du tilgang til A-kortet, et eksklusivt fordelsprogram som gir deg utallige fordeler og rabatter på en rekke gode opplevelser. Med A-kortet kan du glede deg over hundrevis av rabatter på konserter, revyer, teaterforestillinger, sportsarrangementer og andre spennende opplevelser for deg og din familie. Du kan også spare penger på reiser, hoteller, restauranter og kino. A-kortet er din billett til et rikere og mer variert liv.',
    pathname: 'aftenposten-inviterer',
  },
  {
    text: 'Shopping',
    frontpage: true,
    icon: CartIcon,
    subsections: [
      {
        text: 'Handledager',
        id: '1b1bb6d8-b63a-4a5f-a425-639ae1a49539',
        pathname: 'handledager',
      },
      {
        text: 'Vinutstyr',
        id: '2a28a1e5-8fbb-46dc-818d-d635256ee63b',
        pathname: 'vinutstyr',
      },
      {
        text: 'Servering',
        id: '3d37e100-8ca3-47a3-b9d6-fdbc1ec36c91',
        pathname: 'servering',
      },
      {
        text: 'Bøker',
        id: '77da3656-cb90-43bf-854c-bf703440cc96',
        pathname: 'bøker',
      },
      {
        text: 'Bad og soverom',
        id: '6568eeba-6f67-491e-9a40-3f5d5141801f',
        pathname: 'bad-og-soverom',
      },
      {
        text: 'Oppbevaring',
        id: '543ecbb1-f95c-4cd2-a64b-9eeacc705e85',
        pathname: 'oppbevaring',
      },
      {
        text: 'Barn og familie',
        id: 'ccac097d-a110-465f-8fe1-58b1d4cf49b5',
        pathname: 'barn-og-familie',
      },
      {
        text: 'Kjøkkenutstyr',
        id: '4c489644-4aa6-44e4-b3e9-d4863210f45a',
        pathname: 'kjøkkenutstyr',
      },
      {
        text: 'Interiør',
        id: 'bd6ee6bd-eb0e-4d9b-85f6-8935f2abafd5',
        pathname: 'interiør',
      },
      {
        text: 'Våre merker',
        id: 'ba66c96a-885c-435f-bbae-592d69aa3651',
        pathname: 'våre-merker',
      },
      {
        text: 'Se A-kortet Butikk',
        id: '9cc7349e-5658-4eeb-a50c-8c58157d64bb',
        pathname: 'se-a--kortet-butikk',
      },
    ],
    id: 'c457deab-604f-4e69-8dab-60d2a4b695b6',
    seo: 'Som abonnent på Aftenposten får du tilgang til A-kortet, et eksklusivt fordelsprogram som gir deg utallige fordeler og rabatter på en rekke gode opplevelser. Med A-kortet kan du glede deg over hundrevis av rabatter på konserter, revyer, teaterforestillinger, sportsarrangementer og andre spennende opplevelser for deg og din familie. Du kan også spare penger på reiser, hoteller, restauranter og kino. A-kortet er din billett til et rikere og mer variert liv.',
    pathname: 'shopping',
  },
  // Keep this one last
  {
    text: 'Alle kategorier',
    frontpage: false,
    icon: ShuffleIcon,
    id: '',
    seo: 'Som abonnent på Aftenposten får du tilgang til A-kortet, et eksklusivt fordelsprogram som gir deg utallige fordeler og rabatter på en rekke gode opplevelser. Med A-kortet kan du glede deg over hundrevis av rabatter på konserter, revyer, teaterforestillinger, sportsarrangementer og andre spennende opplevelser for deg og din familie. Du kan også spare penger på reiser, hoteller, restauranter og kino. A-kortet er din billett til et rikere og mer variert liv.',
    pathname: 'alle-kategorier',
  },
];

export const getSections = (publication: BenefitsPublications): Sections => {
  const sections = {
    bt: btSections,
    sa: saSections,
    ap: apSections,
  };

  return sections[publication];
};

export const getDefaultSection = (publication: BenefitsPublications) => {
  const [defaultSection] = getSections(publication).slice(-1);

  return defaultSection;
};
