import type {
  BenefitsPublications,
  FrapiTeaserItem,
} from '../types/frapi-types.js';

import { publicationData } from './publication-data.js';
import { getDefaultSection } from './sections.js';

export const getBaseUrl = (
  publication: BenefitsPublications,
  devMode: boolean,
) => {
  if (devMode) {
    return `/${publication}`;
  }

  return `/${publicationData[publication].publicPathRoot}`;
};

export const getLandingUrl = (
  publication: BenefitsPublications,
  devMode: boolean,
) => {
  return `${getBaseUrl(publication, devMode)}/${getDefaultSection(publication).pathname}`;
};

export const getCardUrl = (
  publication: BenefitsPublications,
  devMode: boolean,
) => {
  return `${getBaseUrl(publication, devMode)}/kort`;
};

export const getArticleUrl = (
  publication: BenefitsPublications,
  devMode: boolean,
  article: FrapiTeaserItem,
) => {
  return `${getBaseUrl(publication, devMode)}/f/${article.id}`;
};
