import type { BenefitsPublications } from '../../../benefits/types/frapi-types.js';

type FeedbackVisibilityConfig = {
  [key in BenefitsPublications]: boolean;
};

const feedbackVisibilityConfig: FeedbackVisibilityConfig = {
  ap: false,
  bt: true,
  sa: true,
};

export const shouldInsertUserFeedback = (
  publication: BenefitsPublications,
): boolean => {
  return feedbackVisibilityConfig[publication];
};
