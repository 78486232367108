import styled from '@emotion/styled';

const AnsvarsmerketStyled = styled.div`
  display: block;
  width: 64px;
  height: 64px;
  border: none;
  background-image: url(https://cdn.aftenposten.no/ap/images/footer/ansvarsmerket.svg);

  img {
    max-width: 100%;
  }
`;

export { AnsvarsmerketStyled };
