import React from 'react';

import type { Page, Target, Placement } from '@schibsted/pulse-news-media';
import type { AriaAttributes } from 'react';

import { usePulseContext } from '../PulseProvider.js';

type LinkWithPulseProps = {
  className?: string;
  href: string;
  id: string;
  elementType?: string;
  name: string;
  onResolveAction?: (event: React.MouseEvent) => unknown;
  preventDefaultAction?: boolean;
  children?: React.ReactNode;
  rel?: string;
  innerRef?: React.RefObject<HTMLAnchorElement>;
  target?: string;
  ariaLabel?: string;
  ariaCurrent?: AriaAttributes['aria-current'];
  custom?: Record<string, unknown>;
  page?: Page;
  placement?: Placement;
  position?: string;
  linkTarget?: Target;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onMouseEnter?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => void;
};

const LinkWithPulse: React.FC<LinkWithPulseProps> = ({
  className,
  href,
  id,
  elementType,
  ariaLabel,
  ariaCurrent,
  target,
  rel,
  innerRef,
  name,
  onResolveAction,
  preventDefaultAction = false,
  children,
  custom,
  page,
  placement,
  position,
  linkTarget,
  onClick: onClickProp,
  onMouseEnter,
}) => {
  const { trackClickUIElement } = usePulseContext();

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    if (preventDefaultAction) {
      event.preventDefault();
    }

    trackClickUIElement({
      object: {
        id,
        elementType: elementType ? elementType : 'Button',
        name,
        custom,
        page,
        placement,
        position,
      },
      target: linkTarget,
    }).finally(() => onResolveAction && onResolveAction(event));

    onClickProp?.(event);
  };

  const linkProps = {
    href,
    onClick,
    onMouseEnter,
    ...(className && { className }),
    ...(ariaLabel && { 'aria-label': ariaLabel }),
    ...(ariaCurrent && { 'aria-current': ariaCurrent }),
    ...(target && { target }),
    ...(rel && { rel }),
    ...(innerRef && { ref: innerRef }),
  };

  return <a {...linkProps}>{children}</a>;
};

export { LinkWithPulse };
