import { CookiesProvider, useCookies } from 'react-cookie';
import React, { useState, useEffect } from 'react';

import { EmojiInput } from './EmojiInput.js';
import { CloseButton } from './CloseButton.js';
import { publicationData } from '../../../benefits/resources/publication-data.js';
import { shouldInsertUserFeedback } from './utils.js';
// import { publicationData } from '../../../resources/publication-data';

export type UserFeedbackProps = {
  qid: number;
  publication: keyof typeof publicationData;
  description?: string;
  isMobile?: boolean;
  logoSource?: string;
  showBetaIndicator?: boolean;
  showCloseButton?: boolean;
  devMode: boolean;
};

const BASE_URL = 'https://user-feedback.regional.schibsted.tech';

const makeUserFeedbackUUID = function (): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;

    return v.toString(16);
  });
};

let USERFEEDBACKID = makeUserFeedbackUUID();

export const UserFeedback: React.FC<UserFeedbackProps> = ({
  qid,
  publication,
  description,
  isMobile,
  logoSource,
  showBetaIndicator,
  showCloseButton,
  devMode,
}) => {
  const cookieRated = `uf-${publication}-${qid}-rated${devMode ? '-test' : ''}`;
  const { fallbackImageUrl, publicationName } = publicationData[publication];
  const [cookies, setCookie] = useCookies([cookieRated]);
  const [rated, setRated] = useState(false);
  const [commented, setCommented] = useState(false);
  const [choosenEmoji, setChoosenEmoji] = useState(-1);
  const [activeSendButton, setActiveSendButton] = useState(false);
  const [showDescription, setDescription] = useState(
    description
      ? description
      : `Dette er en tidlig betaversjon av vår nye side for
  ${publicationName} Fordel. Hva syns du om den?`,
  );

  useEffect(() => {
    if (cookies[cookieRated] && !rated) {
      USERFEEDBACKID = cookies[cookieRated];

      if (choosenEmoji < 0) {
        // We have not chosen an emoji this turn, but the cookie has been set.
        // whic means that we have allready voted.
        setRated(true);
      }
    }
  }, [cookieRated, cookies, rated, choosenEmoji]);

  const handleClose = () => {
    setCookie(cookieRated, USERFEEDBACKID, {
      path: '/',
      maxAge: devMode ? 60 : 604800,
    });
    setRated(true);
  };

  const onChange = (state: boolean, index: number) => {
    setChoosenEmoji(index);
    if (state) {
      setCookie(cookieRated, USERFEEDBACKID, {
        path: '/',
        maxAge: devMode ? 60 : 604800,
      });
      setDescription(
        `${
          isMobile
            ? 'Takk! Har du andre kommentarer?'
            : 'Takk for tilbakemeldingen! Har du andre kommentarer?'
        }`,
      );
    }

    //setIsDone(state);
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent the default form submission behavior
  };

  // One function to update the text from the
  // textarea
  function handleText(e: React.ChangeEvent<HTMLTextAreaElement>) {
    if (e.target.value.length === 0 && activeSendButton) {
      setActiveSendButton(false);
    } else if (e.target.value.length > 0) {
      setActiveSendButton(true);
    }
  }

  const emojiInputs = [
    { value: 1, emoji: '😩' },
    { value: 2, emoji: '🙁' },
    { value: 3, emoji: '😐' },
    { value: 4, emoji: '🙂' },
    { value: 5, emoji: '😍' },
  ];

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    // Prevent the browser from reloading the page
    e.preventDefault();

    if (!activeSendButton) {
      setCommented(true);

      return;
    }

    // Read the form data
    const form = e.currentTarget;
    const formData = new FormData(form);

    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');

    const postContent = formData.get('postContent');
    const comment = postContent ? postContent.toString() : '';

    fetch(`${BASE_URL}/comment`, {
      method: 'POST',
      headers: requestHeaders,
      body: new URLSearchParams({
        publication: `${publication}${devMode ? '-test' : ''}`,
        feedbackId: USERFEEDBACKID,
        comment: comment,
      }),
    }).then(() => {
      setCommented(true);
    });
  }
  if (rated) {
    return <></>;
  }

  if (!shouldInsertUserFeedback(publication)) return null;

  const dontShowLogo = choosenEmoji > 0 && isMobile && !commented;

  return (
    <CookiesProvider>
      <div className="regional-feedback">
        {showBetaIndicator && (
          <div className="regional-feedback--beta-indicator">BETA</div>
        )}
        {showCloseButton && <CloseButton onClick={handleClose} />}
        {!commented ? (
          <>
            <p className="regional-feedback--title">{showDescription}</p>
            <form
              className="regional-feedback--form"
              onSubmit={handleFormSubmit}
            >
              <div className="regional-feedback--emoji-container">
                {emojiInputs.map((emoji) => (
                  <EmojiInput
                    key={emoji.value}
                    emoji={emoji.emoji}
                    value={emoji.value}
                    id={USERFEEDBACKID}
                    questionId={qid}
                    publication={publication}
                    stateChanger={onChange}
                    choosen={choosenEmoji}
                    devMode={devMode}
                  ></EmojiInput>
                ))}
              </div>
            </form>
            {choosenEmoji > 0 && (
              <form
                className="regional-feedback--form--text"
                method="post"
                onSubmit={handleSubmit}
              >
                <textarea
                  name="postContent"
                  placeholder="Fortell oss hva du mener..."
                  className="regional-feedback--textarea"
                  onChange={handleText}
                />
                <button
                  type="submit"
                  className={`regional-feedback--textarea-button ${
                    activeSendButton
                      ? 'regional-feedback--textarea-button--active'
                      : ''
                  }`}
                >
                  {`${
                    activeSendButton ? 'Send kommentar' : 'Send uten kommentar'
                  }`}
                </button>
              </form>
            )}
          </>
        ) : (
          <div className="regional-feedback--container--finished">
            <p className="regional-feedback--title regional-feedback--title--message">
              Takk for at du er med på å gjøre {publicationName} bedre for alle!
            </p>
            <p className="regional-feedback--checkmark">✅</p>
          </div>
        )}
        {!dontShowLogo && (
          <div className="regional-feedback--logo">
            <img src={logoSource ? logoSource : fallbackImageUrl} alt="" />
          </div>
        )}
      </div>
    </CookiesProvider>
  );
};
