import React from 'react';

import type { Text } from '../../../../../core/data-sources/content-components/types.js';

import { transformMarkup } from './TextHelpers.js';

type ListItemsProps = {
  items: Text[];
};

const ListItems: React.FC<ListItemsProps> = ({ items }) => {
  return items.map((item, index) => (
    <li key={`item-${index}`}>
      {item.markup ? transformMarkup(item.markup, item.value) : item.value}
    </li>
  ));
};
export default ListItems;
