import React from 'react';

export type CloseProps = {
  onClick: () => void;
};

export const CloseButton: React.FC<CloseProps> = ({ onClick }) => {
  return (
    <button className="regional-feedback--button--close" onClick={onClick}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.66894 3.60834C4.37605 3.31545 3.90117 3.31545 3.60828 3.60834C3.31539 3.90124 3.31539 4.37611 3.60828 4.669L7.93933 9.00005L3.60833 13.3311C3.31543 13.624 3.31543 14.0988 3.60833 14.3917C3.90122 14.6846 4.37609 14.6846 4.66899 14.3917L8.99999 10.0607L13.331 14.3917C13.6239 14.6846 14.0988 14.6846 14.3917 14.3917C14.6846 14.0988 14.6846 13.624 14.3917 13.3311L10.0607 9.00005L14.3917 4.669C14.6846 4.37611 14.6846 3.90124 14.3917 3.60834C14.0988 3.31545 13.6239 3.31545 13.331 3.60834L8.99999 7.93939L4.66894 3.60834Z"
          fill="black"
          fillOpacity="0.5"
        />
      </svg>
    </button>
  );
};
