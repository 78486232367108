import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  createContext,
} from 'react';
import { NewsMediaPulseTracker } from '@schibsted/pulse-news-media';
import { Tracker as PulseTracker } from '@spt-tracking/pulse-sdk';

import type {
  PulseContextProviderProps,
  PulseContextValue,
  TrackEvent,
  RegisterPageLeaveTracking,
  SDKConfigInput,
  BaseEventData,
} from './types/index.js';

import {
  getActorPromise,
  handleDebugMode,
  handleProductTag,
} from './utils/index.js';
import { defaultPulseConfig } from './config.js';
import { createElementViewEventData } from './event-data/index.js';
import { provideFakePulseAutotracker } from './pulse-autotracker-fake.server.js';
import { getTcfConfig } from '../../../../public-src/core/js/sourcepoint.js';
import {
  isLocalhost,
  isReviewApp,
} from '../../../../public-src/core/js/utils/is-localhost.js';

export type AltEventHandler = NonNullable<SDKConfigInput['altEventHandler']>;

const defaultNewsMediaPulse: NewsMediaPulseTracker = {
  pulseTracker: {} as PulseTracker,
  trackViewArticle: () => Promise.resolve(null),
  trackViewSalesPoster: () => Promise.resolve(null),
  trackViewFrontpage: () => Promise.resolve(null),
  trackViewListing: () => Promise.resolve(null),
  trackViewPage: () => Promise.resolve(null),
  trackViewLoginPoster: () => Promise.resolve(null),
  addPageLeaveTracking: () => () => {},
  updatePageLeaveTracking: () => {},
  removePageLeaveTracking: () => {},
  trackActivePageLeave: () => {},
  trackPageLeave: () => Promise.resolve(),
  trackViewUIElement: () => Promise.resolve(),
  registerViewUIElementTracking: () => Promise.resolve(),
  trackClickUIElement: () => Promise.resolve(),
};

const PulseContext = createContext<PulseContextValue | null>(null);

const PulseContextProvider: React.FC<PulseContextProviderProps> = ({
  config,
  children,
}) => {
  const [pulse, setPulse] = useState<PulseTracker>();
  const [newsMediaPulse, setNewsMediaPulse] = useState<NewsMediaPulseTracker>(
    defaultNewsMediaPulse,
  );

  const { providerId } = config;

  useEffect(() => {
    const deployStage = isLocalhost() || isReviewApp() ? 'dev' : 'pro';

    if (!pulse) {
      const pulseInstance = new PulseTracker(providerId, {
        useBeacon: true,
        useBeaconWhenAvailable: true,
        nativeJwe: window.hermesJwe as string,
        altEventHandler: window.pulseEventHandler as AltEventHandler,
        ...getTcfConfig(),
        deployStage,
      } as SDKConfigInput);

      const actorPromise = getActorPromise();

      const newsMediaPulse = new NewsMediaPulseTracker(
        pulseInstance,
        actorPromise,
      );

      setNewsMediaPulse(newsMediaPulse);

      const { pulseTracker } = newsMediaPulse;

      handleDebugMode(pulseTracker);
      handleProductTag(pulseTracker, config);

      setPulse(pulseTracker);

      provideFakePulseAutotracker(pulseTracker);
    }
  }, [pulse, providerId, config]);

  const trackEvent: TrackEvent = useCallback(
    (input, options = {}) => {
      if (pulse) {
        return pulse.track('trackerEvent', input, options);
      }

      return Promise.resolve(null);
    },
    [pulse],
  );

  const registerPageLeaveTracking: RegisterPageLeaveTracking = useCallback(
    ({ objectElement, pageElement = document.querySelector('body') }) => {
      if (pageElement) {
        newsMediaPulse.addPageLeaveTracking(objectElement, pageElement);
      }
    },
    [newsMediaPulse],
  );

  const getEnvironmentId = useCallback(() => {
    if (pulse) {
      return pulse.getEnvironmentId();
    }

    return Promise.resolve(null);
  }, [pulse]);

  const {
    trackViewArticle,
    trackViewSalesPoster,
    trackViewPage,
    trackViewFrontpage,
    trackViewListing,
    registerViewUIElementTracking,
    trackClickUIElement,
  } = newsMediaPulse;

  const registerViewElementTracking = useCallback(
    (baseEventData: BaseEventData) => {
      const pluginConfig = {
        event: baseEventData,
        beforeTrack: createElementViewEventData,
        query: '[data-pulse-entity-id]',
        idAttr: 'data-pulse-entity-id',
        customAttr: 'data-pulse-custom',
        positionAttr: 'data-pulse-position',
        sourceAttr: 'data-pulse-source',
      };
      registerViewUIElementTracking<BaseEventData>(pluginConfig);
    },
    [registerViewUIElementTracking],
  );

  return (
    <PulseContext.Provider
      value={{
        trackEvent,
        registerViewElementTracking,
        registerPageLeaveTracking,
        getEnvironmentId,
        trackViewArticle,
        trackViewSalesPoster,
        trackViewFrontpage,
        trackViewListing,
        trackViewPage,
        trackClickUIElement,
      }}
    >
      {children}
    </PulseContext.Provider>
  );
};

const usePulseContext = (): PulseContextValue => {
  const context = useContext(PulseContext);

  if (!context) {
    throw new Error('No Pulse context provided');
  }

  return context;
};

const withPulse = <P extends Record<string, unknown>>(
  Component: React.FC<P>,
  pulseConfig = defaultPulseConfig,
): React.FC<P> => {
  const Hoc = (props: P) => {
    return (
      <PulseContextProvider config={pulseConfig}>
        <Component {...props} />
      </PulseContextProvider>
    );
  };

  Hoc.displayName = `withPulse<${Component.displayName}>`;

  return Hoc;
};

export { usePulseContext, withPulse };
