import { clsx } from 'clsx';
import React, { useEffect, useMemo } from 'react';

import type { CuratePulseTrackingCustomProperties } from '../../../../core/utils/types.js';
import type {
  BenefitsPublications,
  FrapiTeaserItem,
} from '../../../types/frapi-types.js';
import type { TeaserCount } from '../../../types/front-types.js';
import type { FrontpageData } from '../../layouts/front-types.js';

import { usePulseContext } from '../../../../core/mandatory-integrations/pulse/PulseProvider.js';
import { generateMetrics } from '../../../../core/mandatory-integrations/pulse/utils/metrics.js';
import { UserFeedback } from '../../../../regional/views-react/feedback-widget/UserFeedback.js';
import { publicationData } from '../../../resources/publication-data.js';
import { getDefaultSection, getSections } from '../../../resources/sections.js';
import {
  SectionLevel,
  filterOutExpired,
  getMainSection,
  getMainSectionId,
  getSectionLevel,
} from '../article-components/Helpers.js';
import { Header } from '../common-components/Header.js';
import { TeaserWidget } from '../common-components/TeaserWidget.js';
import { Footer } from '../footer-components/Footer.js';
import { withPulseBenefits } from '../pulse.js';
import { BenefitsHero } from './BenefitsHero.js';

export type FrontProps = {
  data: FrontpageData;
  isMobile: boolean;
};

const getNumberOfTeasers = (teasers: FrapiTeaserItem[]): TeaserCount => {
  const teaserList: TeaserCount = {};
  teasers.filter(filterOutExpired).forEach((t) => {
    if (t.section.parent) {
      const parent = t.section.parent.id;
      const subsec = t.section.id;
      if (!teaserList[parent]) {
        teaserList[parent] = {
          count: 0,
          sub: {},
        };
      }
      teaserList[parent].count++;
      if (!teaserList[parent].sub[subsec]) {
        teaserList[parent].sub[subsec] = { count: 0 };
      }
      teaserList[parent].sub[subsec].count++;
    } else {
      const section = t.section.title.toLowerCase();
      if (!teaserList[section]) {
        teaserList[section] = {
          count: 0,
          sub: {},
        };
      }
      teaserList[section].count++;
    }
  });

  return teaserList;
};

const Front: React.FC<FrontProps> = ({ data, isMobile }) => {
  const { frapiData, pathname, publication, section, subsection, devMode } =
    data;
  const { properties, personalization: blendPersonalization } = frapiData;

  const articleList = frapiData.items || frapiData.articles;
  const { trackViewListing, registerViewElementTracking } = usePulseContext();
  const defaultSection = getDefaultSection(publication);
  const isAllBenefits = pathname === defaultSection.pathname;

  const personalization: CuratePulseTrackingCustomProperties | null =
    useMemo(() => {
      if (properties && blendPersonalization) {
        return {
          variant: properties.id,
          removeOnRead: blendPersonalization.removeOnRead,
          removeOnImpressed: blendPersonalization.removeOnImpressed,
          personalizedResults: blendPersonalization.personalizedResults,
        };
      }

      return null;
    }, [properties, blendPersonalization]);

  useEffect(() => {
    const pageId = isAllBenefits ? 'Forside' : pathname;
    const object: {
      id: string;
      name: string;
      custom?: CuratePulseTrackingCustomProperties;
    } = {
      id: pageId,
      name: pathname,
    };

    trackViewListing({
      object,
      metrics: generateMetrics(personalization?.variant, personalization),
    });

    registerViewElementTracking({
      object: {
        page: {
          id: pageId,
          type: 'Listing',
        },
      },
    });
  }, [
    isAllBenefits,
    registerViewElementTracking,
    pathname,
    personalization,
    trackViewListing,
  ]);

  const teasers = getTeasers(
    articleList,
    section,
    isMobile,
    data.publication,
    data.devMode,
    subsection,
  );

  return (
    <>
      <div className={clsx('frontpage-container', publication)}>
        <Header publication={publication} devMode={devMode}></Header>
        <div className="page-content">
          <BenefitsHero
            publication={publication}
            isMobile={isMobile}
            activeSection={section}
            devMode={devMode}
            teaserCount={getNumberOfTeasers(articleList)}
            activeSubsection={subsection}
          ></BenefitsHero>
          <div className="articles-container">
            {teasers.map((row, index) => (
              <div
                className={`articles-container-row articles-container-row-${row.length}`}
                key={`${index}-row`}
              >
                {row}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer publication={data.publication} />
    </>
  );
};

export const FrontWithPulse = withPulseBenefits(Front);

function getTeasers(
  items: FrapiTeaserItem[],
  activeSection: string,
  isMobile: boolean,
  publication: BenefitsPublications,
  devMode: boolean,
  activeSubsection?: string,
) {
  const sections = getSections(publication);
  const mainSection = sections.find((s) => s.pathname === activeSection);
  const subsection = activeSubsection
    ? mainSection?.subsections?.find((s) => s.pathname === activeSubsection)
    : undefined;

  const filterOnMainSection = (item: FrapiTeaserItem) =>
    // If the item is in the selected section we take it
    getMainSectionId(item) === mainSection?.id ||
    // or if the selected section is the all benefits section
    mainSection?.id === sections[sections.length - 1].id;

  const filterOnSubsection = (item: FrapiTeaserItem) => {
    if (subsection) {
      return item.section.id === subsection.id;
    }

    return true;
  };

  // Filter out teasers by hotness based on page
  const filterOutLowNewsValue = (item: FrapiTeaserItem) => {
    const newsValueToFilterOn = (() => {
      switch (getSectionLevel(activeSection, sections, activeSubsection)) {
        case SectionLevel.Frontpage:
          return 40;
        case SectionLevel.Section:
          return 30;
        case SectionLevel.SubSection:
          return 20;
      }
    })();

    return item.grade && item.grade >= newsValueToFilterOn;
  };

  const teasers = items
    .filter(filterOutExpired)
    .filter(filterOutLowNewsValue)
    // The order we filter in is important for main and subsections
    .filter(filterOnMainSection)
    .filter(filterOnSubsection);

  if (!isMobile) {
    return PackTeasers(teasers, publication, devMode);
  } else {
    const teaserWidgets = teasers.map((teaser, ind) => {
      const p = ind / teasers.length;
      const pos = p >= 0.25 ? 'top' : p > 0.75 ? 'middle' : 'bottom';

      return [
        <TeaserWidget
          article={teaser}
          key={teaser.id}
          isMobile={true}
          top={true}
          publication={publication}
          devMode={devMode}
          description={getMainSection(teaser)}
          position={pos}
        ></TeaserWidget>,
      ];
    });

    teaserWidgets.splice(teasers.length < 15 ? teasers.length : 15, 0, [
      <UserFeedback
        key={`${teasers.length}-userfeedback`}
        qid={109}
        publication={publication}
        logoSource={publicationData[publication].logoSource}
        devMode={devMode}
      />,
    ]);

    return teaserWidgets;
  }
}

/** Only pack for desktop */
function PackTeasers(
  frapiTeasers: FrapiTeaserItem[],
  publication: BenefitsPublications,
  devMode: boolean,
) {
  const items = frapiTeasers.length;
  const singles: FrapiTeaserItem[] = [];
  const doubles: FrapiTeaserItem[] = [];
  const triples: FrapiTeaserItem[] = [];

  const leftOver = frapiTeasers.length % 5;

  for (let i = 0; i < frapiTeasers.length - leftOver; i++) {
    if (i % 5 < 2) {
      doubles.push(frapiTeasers[i]);
    } else {
      triples.push(frapiTeasers[i]);
    }
  }

  for (let i = frapiTeasers.length - leftOver; i < frapiTeasers.length; i++) {
    switch (leftOver) {
      case 1: {
        singles.push(frapiTeasers[i]);
        break;
      }
      case 2:
      case 4: {
        doubles.push(frapiTeasers[i]);
        break;
      }
      case 3: {
        triples.push(frapiTeasers[i]);
      }
    }
  }

  const teasers: React.ReactElement[][] = [];
  let processed = 0;
  while (processed !== items - singles.length) {
    const p = processed / items - singles.length;
    const pos = p <= 0.25 ? 'top' : p <= 0.75 ? 'middle' : 'bottom';

    let row: React.ReactElement[] = [];
    if (doubles.length > 0) {
      for (let i = 0; i < 2; i++) {
        const teaser = doubles.shift();
        if (!teaser) break;
        row.push(
          <TeaserWidget
            article={teaser}
            key={teaser.id}
            isMobile={false}
            top={true}
            style="double"
            publication={publication}
            description={getMainSection(teaser)}
            devMode={devMode}
            position={pos}
          ></TeaserWidget>,
        );
      }
    }
    processed += row.length;
    teasers.push(row);
    row = [];
    if (triples.length > 0) {
      for (let i = 0; i < 3; i++) {
        const teaser = triples.shift();
        if (!teaser) break;
        row.push(
          <TeaserWidget
            article={teaser}
            key={teaser.id}
            isMobile={false}
            top={true}
            style="triple"
            publication={publication}
            description={getMainSection(teaser)}
            devMode={devMode}
            position={pos}
          ></TeaserWidget>,
        );
      }
    }
    processed += row.length;
    teasers.push(row);
  }

  if (singles.length > 0) {
    const teaser = singles.shift();
    if (teaser) {
      teasers.push([
        <TeaserWidget
          article={teaser}
          key={teaser.id}
          isMobile={false}
          top={false}
          style="single"
          publication={publication}
          description={getMainSection(teaser)}
          devMode={devMode}
          position="bottom"
        ></TeaserWidget>,
      ]);
    }
  }

  teasers.splice(teasers.length < 6 ? teasers.length : 6, 0, [
    <UserFeedback
      key={`${teasers.length}-userfeedback`}
      qid={109}
      publication={publication}
      logoSource={publicationData[publication].logoSource}
      devMode={devMode}
    ></UserFeedback>,
  ]);

  return teasers;
}
