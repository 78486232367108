import type { ListBlock, TextBlock, TextComponent } from './types.js';

import { isObject } from '../../utils/type-guards.js';

export const isComponentType = (component: unknown, type: string) =>
  isObject(component) &&
  'type' in component &&
  typeof component.type === 'string' &&
  component.type === type;

export const isTextComponent = (
  component: unknown,
): component is TextComponent => isComponentType(component, 'text');

export const isListBlock = (
  richTextBlock: unknown,
): richTextBlock is ListBlock =>
  isObject(richTextBlock) &&
  'items' in richTextBlock &&
  Array.isArray(richTextBlock.items);

export const isTextBlock = (
  richTextBlock: unknown,
): richTextBlock is TextBlock =>
  isObject(richTextBlock) &&
  'text' in richTextBlock &&
  isObject(richTextBlock.text);
