import type { Tracker as PulseTracker } from '@spt-tracking/pulse-sdk';

const realPulseAutotrackerExists = !!window.pulse;

if (typeof window === 'object') {
  if (!realPulseAutotrackerExists) {
    window.pulse = function (...args) {
      (window.pulse.q = window.pulse.q || []).push(args);
    };
  }
}

export function provideFakePulseAutotracker(trackerInstance: PulseTracker) {
  if (realPulseAutotrackerExists) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pastInvocationsQueue: [string, any, any, boolean][] | undefined =
    window?.pulse.q;

  window.pulse = (...args: unknown[]) => {
    if (args.length === 0) {
      return;
    }

    if (typeof args[0] === 'function') {
      return args[0](trackerInstance);
    }

    if (typeof args[0] === 'string') {
      if (args[0] === 'init') {
        throw new Error("window.pulse('init', ...) is not supported");
      } else if (typeof args[1] === 'function') {
        // always return a default tracker
        args[1](trackerInstance);
      }
    }
  };

  if (pastInvocationsQueue && pastInvocationsQueue.length) {
    pastInvocationsQueue.forEach((args) => window.pulse.apply(null, args));
  }
}
