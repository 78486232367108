import React from 'react';

import type {
  ListBlockTypes,
  Text,
} from '../../../../../core/data-sources/content-components/types.js';

import ListItems from './ListItems.js';

type ListProps = {
  items: Text[];
  type: ListBlockTypes;
  id?: string;
};

const List: React.FC<ListProps> = ({ items, type }) => {
  if (type === 'list:unordered') {
    return (
      <ul className="list">
        <ListItems items={items}></ListItems>
      </ul>
    );
  }
  if (type === 'list:ordered') {
    return (
      <ol className="list">
        <ListItems items={items}></ListItems>
      </ol>
    );
  }

  return null;
};

export default List;
