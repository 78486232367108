import styled from '@emotion/styled';

const FooterStyled = styled.div`
  --gridBase: 8px;

  background: var(--c-dark, #000724);
  width: 100%;
  font: 400 16px/18px Graphik;

  box-sizing: border-box;

  footer {
    display: flex;
    flex-direction: column;

    #data-controller-permanent {
      position: unset;
      width: 100%;
    }
  }

  a {
    color: white;
  }

  .container {
    display: flex;
    gap: 88px;
    justify-content: center;
    width: 100%;
    margin: calc(var(--gridBase) * 8) 0px;
  }

  .links,
  .info {
    height: 100%;

    ul {
      width: 100%;
      list-style: none;
      padding: 0px;
      margin: 0px;
    }

    li {
      padding-bottom: 12px;

      &:last-of-type {
        padding: 0px;
      }
    }
  }

  .links {
    display: flex;
    flex-direction: column;
    gap: calc(var(--gridBase) * 4);
    min-width: 202px;

    .telephone {
      font-weight: bold;
    }

    .logos {
      display: flex;
      margin-top: var(--gridBase);
      gap: calc(var(--gridBase) * 4);
    }

    ul li a {
      color: white;
      text-decoration: none;
    }
  }

  .info {
    height: auto;
    display: flex;
    flex-direction: column;
    gap: calc(var(--gridBase) * 5);
    max-width: 693px;
    padding-right: 0;

    li {
      .name {
        font-weight: 600;
      }
    }

    color: white;

    a {
      text-decoration: underline;

      &:visited,
      &:link {
        color: white;
      }

      &:hover {
        color: #0059d2;
      }
    }

    .contact-info {
      a {
        font-weight: 500;
      }
    }
  }

  @media screen and (max-width: 980px) {
    .container {
      flex-direction: column;
      font-size: 14px;
      gap: calc(var(--gridBase) * 3);
      margin: calc(var(--gridBase) * 8) calc(var(--gridBase) * 2);
      width: auto;
    }

    .links,
    .info {
      gap: calc(var(--gridBase) * 3);

      li {
        padding-bottom: var(--gridBase);

        &:last-of-type {
          padding: 0px;
        }
      }

      .logos {
        margin: 0px;
      }
    }

    .info .disclaimer {
      margin: 0px;
      font-size: 16px;
    }
  }
`;

export { FooterStyled };
