import type React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const rootComponentProps: any;

export async function hydrateReactRootComponent<U, T extends React.FC<U>>(
  component: T,
) {
  const rootComponent = document.querySelector('#rootComponent');

  const { createElement, hydrateRoot } = await import('./react-library.js');

  hydrateRoot(rootComponent, createElement(component, rootComponentProps));
}
