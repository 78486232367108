import React from 'react';
import { clsx } from 'clsx';

import type { Page, Placement } from '@schibsted/pulse-news-media';
import type {
  BenefitsPublications,
  FrapiTeaserItem,
} from '../../../types/frapi-types.js';

import { TeaserImage } from '../common-components/TeaserImage.js';
import { getFirstDescriptorTag } from '../article-components/Helpers.js';
import { getArticleUrl } from '../../../resources/internal-routes.js';
import { LinkWithPulse } from '../../../../core/mandatory-integrations/pulse/components/LinkWithPulse.js';

type TeaserWidgetProp = {
  article: FrapiTeaserItem;
  isMobile?: boolean;
  top: boolean;
  style?: 'single' | 'double' | 'triple';
  publication: BenefitsPublications;
  description?: string;
  devMode: boolean;
  position: 'top' | 'middle' | 'bottom' | 'recirculation';
  primaryPosition?: number;
  usePromotional?: boolean;
};

const sectionsToTags = ['ANDRE FORDELER'];

const getDesctiption = (
  originalDescription: string,
  article: FrapiTeaserItem,
) => {
  if (
    !originalDescription ||
    sectionsToTags.includes(originalDescription.toUpperCase())
  ) {
    return getFirstDescriptorTag(article);
  }

  return originalDescription;
};

export const TeaserWidget: React.FC<TeaserWidgetProp> = ({
  article,
  top,
  style,
  publication,
  description,
  devMode,
  position,
  primaryPosition,
  usePromotional,
}) => {
  const {
    title,
    presentationProperties,
    promotionContent,
    section,
    articleType,
  } = article;
  description = description && getDesctiption(description, article);

  const teaserContainer = (
    <div className="teaser-container">
      {usePromotional ? (
        <span className={clsx('description', articleType)}>
          {section.parent ? section.parent.title : section.title}
        </span>
      ) : (
        description && (
          <span className={clsx('description', articleType)}>
            {description}
          </span>
        )
      )}

      <div className="teaser-top">
        {usePromotional &&
        promotionContent &&
        promotionContent.title &&
        promotionContent.title.value ? (
          <h3 className={clsx('title', articleType)}>
            {promotionContent.title.value}
          </h3>
        ) : (
          title && <h3 className={clsx('title', articleType)}>{title}</h3>
        )}
        {presentationProperties && presentationProperties.promotekst && (
          <span className={clsx('when-where', articleType)}>
            {presentationProperties.promotekst}
          </span>
        )}
      </div>
      {articleType === 'default' && presentationProperties && (
        <div className={`prices ${usePromotional ? 'promotional-prices' : ''}`}>
          {!usePromotional && <span>{presentationProperties.ekstra}</span>}
          {presentationProperties.knapp && (
            <div className="presentation-button">
              {presentationProperties.knapp}
            </div>
          )}
        </div>
      )}

      {articleType === 'competition' && (
        <div className="prices competition">
          <div className="presentation-button">Delta her</div>
        </div>
      )}
    </div>
  );

  const linkUrl = article.extLink
    ? article.extLink
    : getArticleUrl(publication, devMode, article);

  const page: Page = {
    id: `Benefit-${article.id}`,
    type: 'Article',
  };

  const placement: Placement = {
    curateContext: 'frontpage',
    source: position,
    primaryPosition: primaryPosition || 0,
  };

  return (
    <LinkWithPulse
      name={article.title}
      id={article.id}
      href={linkUrl}
      className={`widget-container ${
        style ? 'widget-container--' + style : ''
      } ${top ? 'top-widget' : ''}`}
      elementType="Teaser"
      page={page}
      placement={placement}
      linkTarget={{
        id: `${article.id}`,
        name: article.title,
        url: linkUrl,
        accessLevel: 'Paid',
        accessLevelName: 'plus',
        type: 'Article',
        newsroom: `${publication}-fordel`,
      }}
    >
      <div
        data-pulse-entity-id={`${publication}-fordel:${article.id}:${description}`}
        data-pulse-entity-name={`Benefit-teaser`}
        data-pulse-entity-type="Teaser"
        className="teaser-widget-pulse-container"
      >
        {style === 'single' ? (
          <div className="article-image-duplicate">
            <div className="duplicate">
              <TeaserImage imageAsset={article.image}></TeaserImage>
            </div>
            <div className="original">
              <TeaserImage imageAsset={article.image}></TeaserImage>
            </div>
          </div>
        ) : (
          <TeaserImage
            imageAsset={article.image}
            teaserStyle={style}
          ></TeaserImage>
        )}
        {teaserContainer}
      </div>
    </LinkWithPulse>
  );
};
