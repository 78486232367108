import React from 'react';
import { ChevronDownIcon } from '@schibsted-regional/components';

import type { ReactNode, SVGProps } from 'react';

type Props = {
  text: string;
  icon: (props: SVGProps<SVGSVGElement>) => ReactNode;
  onTrigger: () => void;
};

export const MobileMenuTrigger = ({ icon, text, onTrigger }: Props) => {
  const IconTag = icon;

  return (
    <button
      className="mobile-menu-trigger"
      aria-label="flere kategorier"
      onClick={onTrigger}
      onKeyDown={onTrigger}
    >
      <IconTag className="pre-icon" width={18} height={18} />
      <span className="text">{text}</span>
      <ChevronDownIcon className="post-icon" width={16} height={16} />
    </button>
  );
};
