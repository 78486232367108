import React from 'react';

import type { BenefitsMenuItem } from './types.js';

import { usePulseContext } from '../../../../../core/mandatory-integrations/pulse/PulseProvider.js';

export const MobileMenuNavigationLink = ({
  active,
  to,
  text,
  prefix,
}: BenefitsMenuItem) => {
  const { trackClickUIElement } = usePulseContext();
  const IconTag = prefix;

  const trackClick = () => {
    trackClickUIElement({
      object: {
        id: `choosing-${text}`,
        name: 'Select Benefit section',
        elementType: 'Button',
      },
    });
  };

  return (
    <a
      href={to}
      aria-current={active ? 'page' : undefined}
      className="mobile-menu-navigation-link"
      onClick={trackClick}
    >
      <IconTag className="prefix" />
      <span className="text">{text}</span>
    </a>
  );
};
