import { PlaceholderCircleIcon } from '@schibsted-regional/components';
import React, { useState } from 'react';

import type { BenefitsPublications } from '../../../../types/frapi-types.js';
import type { BenefitsMenuItem } from './types.js';

import { usePulseContext } from '../../../../../core/mandatory-integrations/pulse/PulseProvider.js';
import { MobileMenuBottomSheet } from './MobileMenuBottomSheet.js';
import { MobileMenuTrigger } from './MobileMenuTrigger.js';

interface DropdownProps {
  publication: BenefitsPublications;
  links: BenefitsMenuItem[];
  devMode: boolean;
}

export const MobileMenu: React.FC<DropdownProps> = ({
  publication,
  links,
  devMode,
}) => {
  const activeSection = links.find((link) => link.active);
  const { trackClickUIElement } = usePulseContext();
  const [menuSheetopen, setMenuSheetOpen] = useState(false);

  const closeDropdown = () => {
    setMenuSheetOpen(false);
  };

  const handleClick = () => {
    trackClickUIElement({
      object: {
        id: `${menuSheetopen ? 'closing' : 'opening'}-mobile-menu`,
        name: 'Bottom sheet menu toggle',
        elementType: 'Button',
      },
    });
    setMenuSheetOpen(!menuSheetopen);
  };

  return (
    <>
      <MobileMenuTrigger
        text={activeSection?.text || ''}
        icon={activeSection?.prefix || PlaceholderCircleIcon}
        onTrigger={handleClick}
      />

      <MobileMenuBottomSheet
        publication={publication}
        devMode={devMode}
        title="Kategorier"
        open={menuSheetopen}
        onClose={closeDropdown}
        links={links}
      />
    </>
  );
};
