import { clsx } from 'clsx';
import React from 'react';

import type { Target } from '@schibsted/pulse-news-media';
import type { BenefitsMenuItem } from './types.js';

import { LinkWithPulse } from '../../../../../core/mandatory-integrations/pulse/components/LinkWithPulse.js';

type Props = {
  elevation?: 'none' | 'medium';
  variant?: 'wrap' | 'scroll';
  links: BenefitsMenuItem[];
};

export const BenefitsSectionSelector = ({
  elevation = 'none',
  variant = 'wrap',
  links,
}: Props) => (
  <nav
    className={clsx(
      'benefits-section-selector',
      `elevation-${elevation}`,
      `variant-${variant}`,
    )}
  >
    {links.map((link, index) => {
      const IconTag = link.prefix;

      return (
        <LinkWithPulse
          key={index}
          href={link.to}
          id={link.text}
          name={link.text}
          className="benefits-section-selector-navigation-link"
          ariaCurrent={link.active ? 'page' : undefined}
          linkTarget={link.pulseTarget as Target}
        >
          <IconTag className="menu-icon" />
          <span>{link.text}</span>
        </LinkWithPulse>
      );
    })}
  </nav>
);
