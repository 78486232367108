import { CheckCircleIcon } from '@schibsted-regional/components';
import { clsx } from 'clsx';
import React from 'react';

import type { BenefitsPublications } from '../../../types/frapi-types.js';
import type {
  SubSectionType,
  TeaserCount,
} from '../../../types/front-types.js';
import type { BenefitsMenuItem } from '../common-components/BenefitsMenu/types.js';

import { usePulseContext } from '../../../../core/mandatory-integrations/pulse/PulseProvider.js';
import { getBaseUrl } from '../../../resources/internal-routes.js';
import { getSections } from '../../../resources/sections.js';
import { MobileMenu } from '../common-components/BenefitsMenu/MobileMenu.js';
import { BenefitsSectionSelector } from '../common-components/BenefitsMenu/BenefitsSectionSelector.js';

type HeroProps = {
  publication: BenefitsPublications;
  isMobile: boolean;
  activeSection: string;
  activeSubsection?: string;
  devMode: boolean;
  teaserCount: TeaserCount;
};

export const BenefitsHero: React.FC<HeroProps> = ({
  activeSection,
  activeSubsection,
  publication,
  devMode,
  teaserCount,
}) => {
  const sections = getSections(publication);
  const baseUrl = getBaseUrl(publication, devMode);

  const navLinks = sections.map((section) => ({
    active: section.pathname === activeSection,
    to: `${baseUrl}/${section.pathname}`,
    text: section.text,
    prefix: section.icon,
    pulseOptions: {
      id: section.text,
      name: section.text,
      elementType: 'Button',
    },
    pulseTarget: {
      id: section.text,
      name: section.text,
      url: `${baseUrl}/${section.pathname}`,
      accessLevel: 'Paid',
      accessLevelName: 'plus',
      type: 'Article',
      newsroom: publication === 'bt' ? 'bt-fordel' : 'sa-fordel',
    },
  })) satisfies BenefitsMenuItem[];

  const activeSelection = sections.find(
    (section) => section.pathname === activeSection,
  );

  return (
    <div className="benefits-hero">
      <div className="hero-image-container">
        <div className="hero-image" />
      </div>
      <div className="hero-content-container">
        <div className="desktop-menu">
          <BenefitsSectionSelector links={navLinks} elevation="medium" />
        </div>
        <div className="mobile-menu">
          <MobileMenu
            publication={publication}
            devMode={devMode}
            links={navLinks}
          />
        </div>

        {activeSelection?.subsections &&
          activeSelection.subsections.length > 0 && (
            <SubSection
              activeSubsection={activeSubsection}
              parentId={activeSelection.id}
              subsections={activeSelection.subsections}
              isMobile={true}
              activeSection={activeSection}
              devMode={devMode}
              publication={publication}
              teaserCount={teaserCount}
            />
          )}
      </div>
    </div>
  );
};

const getSubSectionCount = (
  teaserCount: TeaserCount,
  parentId: string,
  subsection: SubSectionType,
) => {
  return teaserCount[parentId]?.sub[subsection.id]?.count ?? 0;
};

type SubsectionProp = {
  parentId: string;
  subsections: SubSectionType[];
  isMobile: boolean;
  activeSection: string;
  activeSubsection?: string;
  devMode: boolean;
  teaserCount: TeaserCount;
  publication: BenefitsPublications;
};

export const SubSection: React.FC<SubsectionProp> = ({
  parentId,
  subsections,
  activeSubsection,
  activeSection,
  teaserCount,
  publication,
  devMode,
}) => {
  const { trackClickUIElement } = usePulseContext();
  const baseUrl = getBaseUrl(publication, devMode);

  return (
    <div className="subsections">
      {subsections.map((subsection, index) => {
        const isActiveSubsection = activeSubsection === subsection.pathname;
        const articleCount = getSubSectionCount(
          teaserCount,
          parentId,
          subsection,
        );

        // Hide subsection if its empty
        if (articleCount === 0) {
          return <React.Fragment key={index} />;
        }

        return (
          <a
            // If the subsection is active we toggle it off on click
            href={
              isActiveSubsection
                ? `${baseUrl}/${activeSection}`
                : // Subsections can have weird characters in their pathnames
                  `${baseUrl}/${activeSection}/${encodeURIComponent(subsection.pathname)}`
            }
            className={clsx(
              'subsection-button',
              isActiveSubsection && 'nav-selected',
            )}
            key={`subsection-${index}`}
            onClick={() => {
              if (isActiveSubsection) {
                trackClickUIElement({
                  object: {
                    id: `deselecting-subsection-${subsection.text}`,
                    name: 'Dropdown menu toggle button',
                    elementType: 'Button',
                  },
                });
              } else {
                trackClickUIElement({
                  object: {
                    id: `selecting-subsection-${subsection.text}`,
                    name: 'Dropdown menu toggle button',
                    elementType: 'Button',
                  },
                });
              }
            }}
          >
            {isActiveSubsection && <CheckCircleIcon width={18} height={18} />}

            <span>{`${subsection.text}`}</span>
            <span className="chip-count">{`(${articleCount})`}</span>
          </a>
        );
      })}
    </div>
  );
};
