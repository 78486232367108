const btSalesPosterUrl =
  'https://kampanje.bt.no/bli-abonnent?utm_source=BT-listing&utm_content=buy-button&utm_medium=rewards&utm_campaign=default-1kr';
const apSalesPosterUrl =
  'https://kampanje.aftenposten.no/bli-abonnent-1kr?utm_source=AP-listing&utm_content=buy-button&utm_medium=rewards&utm_campaign=default-1kr';

type BenefitsConfig = {
  fordelName: string;
  publicationName: string;
  publicationUrl: string;
  backToFrontText?: string;
  backToFrontIcon: string;
  logoSource: string;
  cardTitle: string;
  minsideUrl: string;
  publicPathRoot: string;
  fallbackImageUrl: string;
  salesposterText?: string;
  salesposterUrl: string;
  defaultSubscriptionLocation: string;
};

type BenefitsConfigRegional = BenefitsConfig & {
  salesPosterManagerUrl: string;
  salesPosterManagerStageUrl: string;
};

export const publicationData: {
  bt: BenefitsConfigRegional;
  sa: BenefitsConfigRegional;
  ap: BenefitsConfig;
} = {
  bt: {
    fordelName: 'BT Fordel',
    publicationName: 'BT',
    publicationUrl: 'https://www.bt.no',
    backToFrontText: 'bt.no',
    backToFrontIcon: 'https://cdn.bt.no/benefits/bt-monogram-circle-black.svg',
    logoSource: 'https://cdn.bt.no/benefits/bt-fordel-logo.svg',
    cardTitle: 'BT fordelskort',
    minsideUrl: 'https://kundeportal.bt.no/minside',
    publicPathRoot: 'fordel',
    fallbackImageUrl: 'https://cdn.bt.no/benefits/bt-fallback-image.svg',
    salesposterUrl: btSalesPosterUrl,
    defaultSubscriptionLocation: btSalesPosterUrl,
    salesPosterManagerUrl: 'https://cm.bt.no/v2/bt/kystbenefit',
    salesPosterManagerStageUrl: 'https://test-cm.bt.no/v2/bt/kystbenefit',
  },
  sa: {
    fordelName: 'Fordel',
    publicationName: 'Aftenbladet',
    publicationUrl: 'https://www.aftenbladet.no',
    backToFrontIcon: 'https://cdn.bt.no/benefits/sa-logo.svg',
    logoSource: 'https://cdn.bt.no/benefits/sa-fordel-logo-v3.svg',
    cardTitle: 'Aftenbladet fordelskort',
    minsideUrl: 'https://kundeportal.aftenbladet.no/minside',
    publicPathRoot: 'fordel',
    fallbackImageUrl: 'https://cdn.bt.no/benefits/sa-fallback-image-v2.svg',
    salesposterText: 'Mer innsikt, flere fordeler\n\n1 måned for 1 kr',
    salesposterUrl:
      'https://abo.aftenbladet.no/bli-abonnent?utm_source=SA-listing&utm_content=buy-button&utm_medium=rewards&utm_campaign=default&redirect_uri=https://www.aftenbladet.no/fordel/a-fordel',
    defaultSubscriptionLocation:
      'https://kampanje.aftenbladet.no/bli-abonnent?utm_source=SA-listing&utm_content=buy-button&utm_medium=rewards&utm_campaign=default-1kr',
    salesPosterManagerUrl: 'https://cm.aftenbladet.no/v2/sa/kystbenefit',
    salesPosterManagerStageUrl:
      'https://test-cm.aftenbladet.no/v2/sa/kystbenefit',
  },
  // TODO: Correct fallbackImageUrl
  ap: {
    fordelName: 'A-kortet',
    publicationName: 'Aftenposten',
    publicationUrl: 'https://www.aftenposten.no',
    backToFrontText: 'Ap.no',
    backToFrontIcon: 'https://cdn.aftenposten.no/benefits/arrow-left-2.svg',
    logoSource: 'https://cdn.aftenposten.no/benefits/a-kortet-logo.svg',
    cardTitle: 'A-kortet',
    minsideUrl: 'https://kundeportal.aftenposten.no/minside',
    publicPathRoot: 'fordel',
    fallbackImageUrl: 'https://cdn.bt.no/benefits/sa-fallback-image.svg',
    salesposterUrl: apSalesPosterUrl,
    defaultSubscriptionLocation: apSalesPosterUrl,
  },
};
