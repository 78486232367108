import React from 'react';

import { FooterStyled } from './Footer.styles.js';
import { LogoApCircular } from './LogoApCircular.js';
import { Ansvarsmerket } from './Ansvarsmerket.js';

const Footer: React.FC = () => {
  return (
    <FooterStyled>
      <footer role="contentinfo">
        <div className="container">
          <div className="links">
            <ul>
              <li>
                <a
                  className="actionLink"
                  href="https://www.aftenposten.no/tips"
                >
                  Tips oss
                </a>
              </li>
              <li>
                <a
                  href="https://www.aftenposten.no/meninger/debatt/i/Ooyk/informasjon-om-debattinnlegg-og-kronikker-til-aftenposten"
                  className="actionLink"
                >
                  Send inn debattinnlegg
                </a>
              </li>
              <li>
                <a
                  href="https://www.aftenposten.no/rettelser"
                  className="actionLink"
                >
                  Endringslogg
                </a>
              </li>
              <li>
                <a
                  className="actionLink"
                  href="https://www.aftenposten.no/slik-jobber-vi-i-aftenposten"
                >
                  Slik jobber vi i Aftenposten
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a
                  className="actionLink"
                  href="https://kundeportal.aftenposten.no/kundeservice/"
                >
                  Kundeservice
                </a>
              </li>
              <li>
                <a
                  className="actionLink"
                  href="https://annonseweb.schibsted.no/nb/brands/aftenposten-4698"
                >
                  Annonsere i Aftenposten
                </a>
              </li>
              <li>
                <a
                  className="actionLink"
                  href="https://info.privacy.schibsted.com/no/schibsted-norge-personvernerklaering/"
                >
                  Personvernpolicy
                </a>
              </li>
              <li>
                <a
                  className="actionLink"
                  href="https://kundeportal.aftenposten.no/om-informasjonskapsler-cookies-og-lokal-lagring-av-data/"
                >
                  Informasjonskapsler
                </a>
              </li>
            </ul>
            <div className="logos">
              <LogoApCircular />
              <Ansvarsmerket />
            </div>
          </div>
          <div className="info">
            <ul>
              <li>
                <span>Sjefredaktør og adm. direktør: </span>
                <span className="name">Trine Eilertsen</span>
              </li>
              <li>
                <span>Nyhetsredaktør: </span>
                <span className="name">Tone Tveøy Strøm-Gundersen</span>
              </li>
              <li>
                <span>Politisk redaktør: </span>
                <span className="name">Kjetil B. Alstadheim</span>
              </li>
              <li>
                <span>Featureredaktør: </span>
                <span className="name">Lillian Vambheim</span>
              </li>
              <li>
                <span>Kulturredaktør: </span>
                <span className="name">Cecilie Asker</span>
              </li>
              <li>
                <span>Debattredaktør: </span>
                <span className="name">Erik Tornes</span>
              </li>
              <li>
                <span>Utviklingsredaktør: </span>
                <span className="name">Jostein Larsen Østring</span>
              </li>
              <li>
                <span>Redaktør avis og forlag: </span>
                <span className="name">Audun Solberg</span>
              </li>
              <li>
                <span>Tilsynsorgan: </span>
                <span className="name">Medietilsynet</span>
              </li>
            </ul>
            <div className="disclaimer">
              Alt innhold er{' '}
              <a
                className="inlineLink"
                href="https://www.aftenposten.no/norge/i/QxPlR/generelle-vilkaar"
              >
                opphavsrettslig beskyttet.
              </a>{' '}
              © Aftenposten. Aftenposten arbeider etter{' '}
              <a
                className="inlineLink"
                href="https://presse.no/Etisk-regelverk/Vaer-Varsom-plakaten"
              >
                Vær Varsom-plakatens
              </a>{' '}
              regler for god presseskikk. Aftenposten har ikke ansvar for
              innhold på eksterne nettsider som det lenkes til. Kopiering av
              materiale fra Aftenposten for bruk annet sted, crawling, skraping,
              indeksering (for eksempel tekst og datamining) er ikke tillatt
              uten avtale.
            </div>
            <div className="contact-info">
              <span>
                Aftenposten sentralbord:{' '}
                <a className="inlineLink" href="tel:22863000">
                  Tlf. 22 86 30 00
                </a>
              </span>
              <span className="break"> – </span>
              <span>
                Adresse:{' '}
                <a
                  className="inlineLink"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://goo.gl/maps/XgFjg"
                >
                  Akersgata 55, 0180 Oslo
                </a>
              </span>
            </div>
          </div>
        </div>
      </footer>
    </FooterStyled>
  );
};
export { Footer };
