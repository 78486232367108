import React from 'react';
import { markupTransformer } from '@svdse/markup-transformer';

import type { Markup } from '../../../../../core/data-sources/content-components/types.js';

function createExternalLinkTag(
  children: string | React.JSX.Element,
  data: unknown,
) {
  const item = data as { uri?: string };
  if (!item.uri) {
    return children;
  }
  if (/^mailto:/.test(item.uri)) {
    return <a href={item.uri}>{children}</a>;
  }

  const useTargetBlank = true;

  return (
    <a
      href={item.uri}
      target={useTargetBlank ? '_blank' : undefined}
      rel={useTargetBlank ? 'noopener noreferrer' : undefined}
    >
      {children}
    </a>
  );
}
function createInternalLinkTag(
  children: string | React.JSX.Element,
  data: unknown,
) {
  const item = data as { uri?: string };
  if (!item.uri) {
    return children;
  }

  const useTargetBlank = true;

  const articleId = item.uri.split(':').pop();

  return (
    <a
      href={`/fordel/f/${articleId}`}
      target={useTargetBlank ? '_blank' : undefined}
      rel={useTargetBlank ? 'noopener noreferrer' : undefined}
    >
      {children}
    </a>
  );
}

const transform = markupTransformer<string | React.JSX.Element, Markup>({
  markupHandlers: {
    'style:em': (children) => <em>{children}</em>,
    'style:strong': (children) => <strong>{children}</strong>,
    'link:external': createExternalLinkTag,
    'link:internal': createInternalLinkTag,
  },
  join: (...parts: React.ReactNode[]) => <>{...parts}</>,
  textTransformer: (text: string) => text,
});

export const transformMarkup = (markup: Markup[], value: string) => {
  return transform({ value, markup });
};
