import React from 'react';

import type { BenefitsPublications } from '../../../types/frapi-types.js';
import type { ImageAsset } from '../../../../core/data-sources/content-components/types.js';

import { LinkWithPulse } from '../../../../core/mandatory-integrations/pulse/components/LinkWithPulse.js';
import Image from './Image.js';

interface LinkInternalProps {
  id: string;
  slug: string;
  title: string;
  image: ImageAsset;
  publication: BenefitsPublications;
}

const LinkInternal: React.FC<LinkInternalProps> = ({
  id,
  title,
  slug,
  image,
  publication,
}) => {
  return (
    <LinkWithPulse
      className="internal-link"
      href={`/artikkel/${id}/${slug}`}
      id="internal-link"
      name="link to internal article"
    >
      <span className="internal-link-header">Sjekk også</span>
      <div className="info-wrapper">
        <p className="internal-link-title">{title}</p>
        <Image
          imageAsset={image}
          articleTitle={title}
          publication={publication}
        />
      </div>
    </LinkWithPulse>
  );
};

export default LinkInternal;
