export const calculateRatio = (w: number, h: number) => {
  return Math.round((w / h) * 10) / 10;
};

export const articleSizes = (
  r: number,
  isMobile: boolean,
  fullWidth: boolean,
) => {
  if (fullWidth) {
    return isMobile ? { w: 980, h: 1536 } : { w: 2048, h: 878 }; // 21:9
  } else if (r === 1) {
    return isMobile ? { w: 960, h: 960 } : { w: 960, h: 960 }; // 1:1
  } else if (r === 1.8) {
    return isMobile ? { w: 780, h: 439 } : { w: 980, h: 551 }; // 16:9
  } else if (r === 0.7) {
    return isMobile ? { w: 720, h: 1080 } : { w: 650, h: 1020 }; // 2:3
  } else if (r === 0.9) {
    return isMobile ? { w: 650, h: 720 } : { w: 650, h: 720 }; // 9: 10
  } else if (r === 2) {
    return isMobile ? { w: 960, h: 405 } : { w: 960, h: 426 }; // 21:9
  }

  return isMobile ? { w: 960, h: 640 } : { w: 1280, h: 853 }; // 3:2
};
