import React from 'react';

type Props = {
  value: string;
};

const ListicleHeading: React.FC<Props> = ({ value }) => {
  return (
    <div className="heading listicle">
      <p>{value}</p>
    </div>
  );
};

export default ListicleHeading;
