import React, { useEffect, useRef, useState } from 'react';
import { init } from '@schibsted/data-controller';

import type { DataControllerSdkOptions } from '@schibsted/data-controller';

const defaultOptions: Partial<DataControllerSdkOptions> = {
  language: 'no',
  layout: 'footer',
  hideAfter: 8000,
  minimizeText: true,
};

export const DataController: React.FC<DataControllerSdkOptions> = (
  dataControllerOptions,
) => {
  const [rendered, setRendered] = useState(false);
  const dataControllerPermanent = useRef(null);

  useEffect(() => {
    if (rendered) {
      return;
    }

    const options = {
      ...defaultOptions,
      ...dataControllerOptions,
    };

    const sdkInstance = init(options);

    sdkInstance.render(dataControllerPermanent.current, {
      permanent: true,
    });

    const dataControllerSticky = document.createElement('div');
    dataControllerSticky.id = 'data-controller-sticky';

    document.body.appendChild(dataControllerSticky);

    sdkInstance.render(dataControllerSticky);

    setRendered(true);
  }, [dataControllerOptions, rendered]);

  return (
    <div id="data-controller-permanent" ref={dataControllerPermanent}></div>
  );
};
