import '../../core/js/set-webpack-public-path.js';
import init from './init.js';
import { hydrateReactRootComponent } from '../../core/js/hydrate-react.js';
import { FrontWithPulse as Front } from '../../../src/benefits/views/components/front-components/Front.js';
import { getSessionData } from '../../core/js/schibsted-account/index.server.js';

hydrateReactRootComponent(Front);

getSessionData().then((sessionData) => {
  if (sessionData) {
    console.log('user is logged in', sessionData);
  } else {
    console.log('user is not logged in');
  }
});

init();
