import React from 'react';

import { AnsvarsmerketStyled } from './Ansvarsmerket.styles.js';

const Ansvarsmerket: React.FC = () => {
  return (
    <a
      href="https://www.redaktor.no/ressurser/etiske-og-juridiske-rammeverk/redaktorplakaten"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Lenke til Redaktørplakaten etiske og juridiske rammeverk på Redaktor.no"
    >
      <AnsvarsmerketStyled>
        <div data-loaded="true">
          <noscript>
            <img
              src="https://cdn.aftenposten.no/ap/images/footer/ansvarsmerket.svg"
              alt="Ansvarsmerket"
            />
          </noscript>
        </div>
      </AnsvarsmerketStyled>
    </a>
  );
};

export { Ansvarsmerket };
