import { clsx } from 'clsx';
import React from 'react';

import type { BenefitsPublications } from '../../../types/frapi-types.js';

import { LinkWithPulse } from '../../../../core/mandatory-integrations/pulse/components/LinkWithPulse.js';
import {
  getLandingUrl,
  getCardUrl,
} from '../../../resources/internal-routes.js';
import { publicationData } from '../../../resources/publication-data.js';

type HeaderProps = {
  publication: BenefitsPublications;
  devMode: boolean;
};

export const Header: React.FC<HeaderProps> = ({ publication, devMode }) => {
  const { publicationUrl, backToFrontText, backToFrontIcon, logoSource } =
    publicationData[publication];

  return (
    <header className={clsx('benefits-header', publication)}>
      <div className="header-container">
        <div className="left">
          <LinkWithPulse
            href={publicationUrl}
            id={`back-to-${publication}`}
            name="Return from fordel to publication"
            className="back-link"
          >
            <img src={backToFrontIcon} alt="" className="back-logo" />

            {backToFrontText && <div>{backToFrontText}</div>}
          </LinkWithPulse>
        </div>

        <LinkWithPulse
          className="center"
          href={getLandingUrl(publication, devMode)}
          id="to-benefit-frontpage"
          name="Return to Benefit frontpage"
        >
          <img src={logoSource} alt={publicationData[publication].fordelName} />
        </LinkWithPulse>

        <div className="right">
          <div className="show-benefit-card">
            <LinkWithPulse
              className="benefits-card-button"
              id="to-card-page"
              name="Benefit Card Page"
              href={getCardUrl(publication, devMode)}
            >
              Vis kort
              <figure className="card-icon">
                <img
                  src="https://cdn.bt.no/benefits/credit-card-white.svg"
                  alt=""
                />
              </figure>
            </LinkWithPulse>
          </div>
        </div>
      </div>
    </header>
  );
};
