import React from 'react';
import { round } from 'lodash-es';

import type { ImageAsset } from '../../../../core/data-sources/content-components/types.js';

import { getImageUrl } from '../article-components/Image.js';

type TeaserImageProps = {
  imageAsset: ImageAsset;
  teaserStyle?: 'single' | 'double' | 'triple';
};

export const TeaserImage: React.FC<TeaserImageProps> = ({
  imageAsset,
  teaserStyle,
}) => {
  const { id, size, hotspot } = imageAsset;
  const imgSrc = teaserStyle
    ? getImageUrl(id) + getTeaserImgParams(teaserStyle)
    : getImageUrl(id);

  // calculate zoom
  const ratioX = hotspot && size && size.width / hotspot.width;
  const ratioY = hotspot && size && size.height / hotspot.height;
  const ratio = ratioX && ratioY && ratioX < ratioY ? ratioX : ratioY;
  const zoom = hotspot && ratio ? round(ratio * 100) + '%' : 'cover';

  // calculate position
  const offsetX =
    hotspot && size && round((hotspot.x / (size.width - hotspot.width)) * 100);
  const offsetY =
    hotspot &&
    size &&
    round((hotspot.y / (size.height - hotspot.height)) * 100);
  const posX = hotspot ? `${offsetX}%` : 'center';
  const posY = hotspot ? `${offsetY}%` : 'center';

  const styles = {
    backgroundImage: `url(${imgSrc})`,
    backgroundSize: `${zoom}`,
    backgroundPosition: `${posX} ${posY}`,
  };

  return <div className="teaser-image" style={styles}></div>;
};

const getTeaserImgParams = (teaserStyle: string) => {
  if (teaserStyle === 'triple') {
    return '?fit=crop&h=214&q=80&tight=true&w=380';
  }
  if (teaserStyle === 'double') {
    return '?fit=crop&h=270&q=80&tight=true&w=480';
  }
  if (teaserStyle === 'single') {
    return '?fit=crop&h=453&q=80&tight=true&w=680';
  } else {
    return '?fit=crop&h=270&q=80&tight=true&w=480';
  }
};
