import React from 'react';

import type { TextComponent } from '../../../../../core/data-sources/content-components/types.js';

import { transformMarkup } from './TextHelpers.js';
import ListicleHeader from './ListicleHeader.js';
import List from './List.js';
import {
  isListBlock,
  isTextBlock,
} from '../../../../../core/data-sources/content-components/type-guards.js';

interface TextProps extends TextComponent {
  isFact?: boolean;
}

const Text: React.FC<TextProps> = (props: TextProps) => {
  const isLead = props.subtype === 'lead';

  const classNames = [
    'text-container',
    props?.presentationProperties?.style === 'border' ? 'border' : '',
  ].join(' ');

  return (
    <div className={classNames}>
      {props.paragraphs.map((paragraph, index) => {
        const { value, markup } =
          (isTextBlock(paragraph) && paragraph.text) || {};

        const isHeading = paragraph.blockType === 'heading';

        if (isLead) {
          return (
            <p className="lead-text" key={`lead-${index}`}>
              {value}
            </p>
          );
        }
        if (isHeading) {
          return (
            value && <ListicleHeader value={value} key={`listicle-${index}`} />
          );
        }

        if (isListBlock(paragraph)) {
          const items = paragraph.items;

          return (
            <List
              key={`unordered-list-${index}`}
              items={items}
              type={paragraph.blockType}
            />
          );
        }

        if (!value) return null;

        return (
          <p key={`paragraph-${index}`} className={`paragraph ${index}`}>
            {markup ? transformMarkup(markup, value) : value}
          </p>
        );
      })}
    </div>
  );
};

export default Text;
