import React from 'react';

import { LogoApCircularStyled } from './LogoApCircular.styles.js';

const LogoApCircular: React.FC = () => {
  return (
    <LogoApCircularStyled>
      <div data-loaded="true">
        <noscript>
          <img
            width=""
            src="https://cdn.aftenposten.no/ap/images/footer/circular-ap-logo.png"
            alt="aftenposten-logo"
          />
        </noscript>
      </div>
    </LogoApCircularStyled>
  );
};

export { LogoApCircular };
