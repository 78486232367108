import React from 'react';
import { BTFooter, SAFooter } from '@schibsted-regional/components';

import type { BenefitsPublications } from '../../../types/frapi-types.js';

import { DataController } from '../../../../core/react/DataController.js';
import { Footer as APFooter } from '../../../../aftenposten/views-react/Footer/Footer.js';

export type FooterProps = {
  publication: BenefitsPublications;
};

const footerData: Record<
  BenefitsPublications,
  { brandName: string; footerComponent: JSX.Element }
> = {
  bt: {
    brandName: 'Bergens Tidende',
    footerComponent: <BTFooter />,
  },
  sa: {
    brandName: 'Stavanger Aftenblad',
    footerComponent: <SAFooter />,
  },
  ap: {
    brandName: 'Aftenposten',
    footerComponent: <APFooter />,
  },
};

export const Footer: React.FC<FooterProps> = ({ publication }) => {
  const { brandName, footerComponent } = footerData[publication];

  return (
    <>
      {footerComponent}
      <DataController brandName={brandName} />
    </>
  );
};
