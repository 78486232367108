import React from 'react';

import type { BenefitsPublications } from '../../../types/frapi-types.js';
import type {
  ImageAsset,
  Originator,
} from '../../../../core/data-sources/content-components/types.js';

import { zoom } from '../../hooks/use-hotspot.js';
import {
  calculateRatio,
  articleSizes,
} from '../../components/common-components/image-utils.js';
import { publicationData } from '../../../resources/publication-data.js';
import { frontendConfig } from '../../../../core/system/config/frontend.server.js';

type ImageProps = {
  articleTitle: string;
  imageAsset?: ImageAsset;
  byline?: Originator;
  caption?: {
    value: string;
  };
  presentationProperties?: { altText?: string; style?: string };
  lazyLoad?: boolean;
  groupIndex?: number;
  hideByline?: boolean;
  fullWidth?: boolean;
  isMobile?: boolean;
  publication: BenefitsPublications;
  isTeaser?: boolean;
};

const Image: React.FC<ImageProps> = ({
  articleTitle,
  imageAsset,
  byline,
  caption,
  groupIndex,
  hideByline,
  lazyLoad,
  presentationProperties,
  publication,
  isTeaser,
}) => {
  let isLogo = false;
  let isLongLogo = false;
  let isPlaceholder = false;
  if (presentationProperties && presentationProperties.style) {
    isLogo = presentationProperties.style.toLowerCase() === 'logo';
    isLongLogo = presentationProperties.style.toLowerCase() === 'lang logo';
  }

  const isListicleImage = groupIndex ? groupIndex >= 0 : false;
  const { fallbackImageUrl } = publicationData[publication];

  let url = fallbackImageUrl;
  if (imageAsset && imageAsset.id) {
    url = getImageUrl(imageAsset.id);

    const placeholderImageIds = [
      '9a4732ce-7438-4c9c-82e6-1ef25849e0a9',
      '52a8c79c-870e-40ec-aeea-3d1fe7be9d1d',
    ];
    if (placeholderImageIds.includes(imageAsset.id)) {
      isPlaceholder = true;
    }
  }

  let hotspotStyles;
  // console.log(imageAsset);
  if (imageAsset && imageAsset.hotspot && imageAsset.size && isTeaser) {
    const [sx, sy] = zoom(imageAsset.hotspot, imageAsset.size);

    const s = sx > sy ? sy : sx;

    // Get percentage to translate scaled image to put top right corner in 0,0
    const aX = (imageAsset.size.width * s - imageAsset.size.width) / 2.0;
    const aY = (imageAsset.size.height * s - imageAsset.size.height) / 2.0;

    const bX = (aX / (imageAsset.size.width * s)) * 100;
    const bY = (aY / (imageAsset.size.height * s)) * 100;

    // how much to shift image based on hotpot top right corner
    const shiftX = (imageAsset.hotspot.x / imageAsset.size.width) * 100.0;
    const shiftY = (imageAsset.hotspot.y / imageAsset.size.height) * 100.0;

    hotspotStyles = {
      transform: `scale(${sx > sy ? sy : sx}) translate(${bX - shiftX}%, ${
        bY - shiftY
      }%) !important`,
      height: 'auto',
    };
  }

  const altText =
    presentationProperties?.altText || caption?.value || articleTitle;

  let isVerticalImage = false;
  if (imageAsset && imageAsset.size) {
    const { width, height } = imageAsset.size;
    isVerticalImage = calculateRatio(width, height) < 1 ? true : false;
  }

  const classNames = [
    'article-image',
    isVerticalImage ? 'vertical' : '',
    isLogo || isLongLogo ? 'article-logo-image' : '',
    isPlaceholder ? 'placeholder-logo' : '',
  ].join(' ');

  return (
    <div className={classNames}>
      {lazyLoad ? (
        <>
          <noscript>
            <img
              className={`no-script image`}
              src={url}
              alt={altText}
              style={hotspotStyles}
            />
          </noscript>
          <img
            className={`b-lazy image ${isLogo ? 'logo-image' : ''} ${
              isLongLogo ? 'logo-image-long' : ''
            }`}
            src={url}
            alt={altText}
            loading="lazy"
            decoding="async"
            style={hotspotStyles}
          />
        </>
      ) : (
        <img
          src={url}
          alt={altText}
          loading="eager"
          decoding="auto"
          style={hotspotStyles}
        />
      )}
      <div className="image-info">
        {caption && !hideByline && !isListicleImage && (
          <span className="italic">{caption.value}</span>
        )}
        {byline && !hideByline && !isListicleImage && (
          <span className="italic"> Foto: {byline.title} </span>
        )}
      </div>
    </div>
  );
};

export const getImageUrl = (
  id: string,
  // width: number,
  // height: number,
  // fullWidth?: boolean,
  // isMobile?: boolean,
) => {
  // const r = ratio(width, height, fullWidth, isMobile);

  return `${frontendConfig.cdnUrlImageProvider}/images/${id}`;
};

// we'll have to spec these
export const ratio = (
  width: number,
  height: number,
  fullWidth?: boolean,
  isMobile?: boolean,
) => {
  const r = calculateRatio(width, height);

  return articleSizes(r, !!isMobile, !!fullWidth);
};

export default Image;
