/* https://webpack.js.org/configuration/output/#outputpublicpath */

import { console } from '../../../src/core/system/console/index.js';

if (typeof window.__public_path_root_prefix__ === 'string') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  __webpack_public_path__ = `/${window.__public_path_root_prefix__}/seksjon/dist/`;
} else {
  console.error(
    'You tried to set custom webpack public path, but your layout file did not provide the window.__public_path_root_prefix__ variable.\n' +
      'Some JS files might not load ¯\\_(ツ)_/¯',
  );
}

export {};
