import type { PulseAttributes } from '../utils/index.js';
import type { BaseEventData } from '../types/index.js';
import type {
  AccessLevel,
  CreateViewUIElementEventData,
  PageType,
  Target,
} from '@schibsted/pulse-news-media';

import { getPulseAttributesFromElement } from '../utils/index.js';

export const createTargetObject = (
  pulseAttributes: PulseAttributes,
  newsroom: string,
): Target => {
  const {
    entityId,
    entityTargetName,
    entityTargetUrl,
    entityAccessLevel,
    entityAccessLevelName,
  } = pulseAttributes;

  return {
    id: entityId || '',
    type: 'Article',
    name: entityTargetName || '',
    url: entityTargetUrl || '',
    accessLevel: entityAccessLevel as AccessLevel,
    accessLevelName: entityAccessLevelName,
    newsroom,
  };
};

export const createElementViewEventData: CreateViewUIElementEventData<
  BaseEventData
> = ({ elementId, pulseObject }) => {
  const element = document.querySelector(
    `[data-pulse-entity-id="${elementId}"]`,
  );

  if (!element) {
    return null;
  }

  const pulseAttributes = getPulseAttributesFromElement(element);
  const {
    entityId,
    entityName,
    entityType,
    elementPosition,
    elementPositionInBundle,
    elementSize,
    source,
  } = pulseAttributes;

  const { provider, object } = pulseObject;

  const targetNewsroom = provider?.productTag || provider?.id;

  return {
    ...pulseObject,
    object: {
      ...pulseObject.object,
      id: entityId || '',
      elementType: entityType || '',
      name: entityName || '',
      placement: {
        ...(object.placement as Record<string, unknown>),
        primaryPosition: elementPosition || 1,
        positionInBundle: elementPositionInBundle,
        source,
      },
      layout: {
        ...(object.layout as Record<string, unknown>),
        elementSize,
      },
      page: {
        ...object.page,
        url: window.location.href || '',
        type: object.page?.type as PageType,
      },
    },
    target: {
      ...createTargetObject(pulseAttributes, targetNewsroom || ''),
    },
    provider: {
      id: provider?.id || '',
      productTag: provider?.productTag,
      product: 'fastenposten',
      productType: 'ResponsiveWeb',
    },
  };
};
