import { initMblTracker } from '../../core/js/mbl-tracker.js';
import { getSessionData } from '../../core/js/schibsted-account/index.client.js';
import { initSourcepoint } from '../../core/js/sourcepoint.js';

const init = () => {
  getSessionData().then((userStatus) => {
    initMblTracker(userStatus);
  });
  initSourcepoint();
};

export default init;
