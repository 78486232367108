import React from 'react';

export type EmojiInputProps = {
  emoji: string;
  value: number;
  id: string;
  questionId: number;
  publication: string;
  choosen: number;
  stateChanger: (string: boolean, number: number) => void;
  devMode: boolean;
};

const BASE_URL = 'https://user-feedback.regional.schibsted.tech';
const requestHeaders: HeadersInit = new Headers();
requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');

export const EmojiInput: React.FC<EmojiInputProps> = ({
  emoji,
  value,
  id,
  questionId,
  publication,
  choosen,
  stateChanger,
  devMode,
}) => {
  const onClick = () => {
    fetch(BASE_URL, {
      method: 'POST',
      headers: requestHeaders,
      body: new URLSearchParams({
        publication: `${publication}${devMode ? '-test' : ''}`,
        feedbackId: id,
        type: 'emoji',
        rate: value.toString(),
        scale: value.toString(),
        questionId: questionId.toString(),
      }),
    })
      .then(() => {
        stateChanger(true, value);
      })
      .catch((error) => {
        // eslint-disable-next-line no-restricted-globals
        console.error(error);
      });
  };

  return (
    <button
      className={`regional-feedback--user-input-container ${
        choosen >= 0
          ? choosen !== value
            ? 'regional-feedback--user-input--not-chosen'
            : 'regional-feedback--user-input--chosen'
          : ''
      }`}
      onClick={onClick}
    >
      <span className="regional-feedback--user-input">{emoji}</span>
    </button>
  );
};
